<template>
  <div class="p-4 relative" v-if="!isEmpty(pollDetail)">
    <!-- <div class="flex justify-end items-center">
      <div
        class="flex flex-col items-center cursor-pointer text-success ml-4"
        @click="updatePolling('ACTIVE')"
      >
        <PlayIcon class="h-5 w-5 md:h-7 md:w-7" />
        Start
      </div>
      <div
        class="flex flex-col items-center cursor-pointer text-magenta ml-4"
        @click="updatePolling('DISABLED')"
      >
        <StopIcon class="h-5 w-5 md:h-7 md:w-7" />
        Stop
      </div>
    </div> -->
    <div v-if="data.isLoading" class="absolute top-0 left-0 h-full w-full bg-grey-dark opacity-75 z-50 flex items-center justify-center">
      <LoadingIcon />
    </div>

    <div class="max-h-screen-2/3 overflow-y-auto mt-1">
      <div class="flex justify-between items-start">
        <p class="text-base p-1 bg-off-white-1 dark:bg-dark-bgPrimary rounded flex-1">
          <template v-if="data.pollingStatus.status === 'ENDED'"> Polling ended </template>
          <template v-else-if="data.pollingStatus.status === 'READY'"> Polling ready </template>
          <template v-else-if="data.pollingStatus.status === 'STARTING'">
            Polling is running :
            <b> {{ data.timeCountDown.minutes }}:{{ data.timeCountDown.seconds }} </b>
          </template>
        </p>
        <div class="flex justify-end items-center">
          <div v-if="data.pollingStatus.status !== 'STARTING'" class="flex flex-col items-center cursor-pointer text-success ml-4" @click="updatePolling('ACTIVE')">
            <PlayIcon class="h-5 w-5 md:h-7 md:w-7" />
            Start
          </div>
          <div v-else class="flex flex-col items-center cursor-pointer text-magenta ml-4" @click="updatePolling('DISABLED')">
            <StopIcon class="h-5 w-5 md:h-7 md:w-7" />
            Stop
          </div>
        </div>
      </div>
      <Question
        v-for="(question, index) in pollDetail.question"
        :key="index"
        :question="question"
        :number="index"
        :isEdit="false"
        v-model="question.name"
        :ref="`question-${index}`"
        :stats="get(data.answerStats, question.id, {})"
      />
    </div>
  </div>
</template>
<script>
import LoadingIcon from "@Components/layouts/LoadingIcon";
import Question from "./Question";
import PlayIcon from "@Assets/images/PlayIcon";
import StopIcon from "@Assets/images/StopIcon";
// import EyeIcon from "@Assets/images/EyeIcon";
import { isEmpty, countBy, forEach, groupBy, get, some } from "lodash";
import { getPollingAnswer, doUpdatePoll } from "@API/event/polling-repository";
import { useSubscription } from "@vue/apollo-composable";
import { reactive, computed, watch, onUnmounted } from "vue";
import { AnswerPollSubcription } from "@/graphql/subscription/Polling";
import { getMomentT } from "@Helpers/date";
import moment from "moment";
export default {
  props: {
    pollDetail: {
      type: Object,
      required: true,
    },
  },
  components: {
    Question,
    LoadingIcon,
    PlayIcon,
    StopIcon,
    // EyeIcon,
  },
  setup(props, { emit }) {
    const data = reactive({
      answers: [],
      answerStats: {},
      isLoading: false,
      timeCountDown: {},
      enableSubscription: false,
      pollingTicker: {},
      pollingTime: 0,
      pollingStatus: {},
    });
    const pollDetail = computed(() => props.pollDetail);

    async function getAnswerPolling() {
      try {
        data.isLoading = true;
        const { eventId, id } = pollDetail.value;
        const resp = await getPollingAnswer(eventId, id);
        data.answers = resp.data;
      } catch (error) {
        console.log({ error });
      } finally {
        data.isLoading = false;
      }
    }

    function calcTime(dist) {
      // Time calculations for days, hours, minutes and seconds
      const minutes = ~~((dist % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = ~~((dist % (1000 * 60)) / 1000);
      data.timeCountDown = {
        minutes: `${minutes < 10 ? "0" : ""}${minutes}`,
        seconds: `${seconds < 10 ? "0" : ""}${seconds}`,
      };
    }

    function onCountingToEnd(pollId) {
      clearInterval(data.pollingTicker[`${pollId}`]);
      data.pollingTicker = {
        ...data.pollingTicker,
        [`${pollId}`]: setInterval(() => {
          data.pollingTime += 1;
          // if (data.pollingTime <= 0) {
          //   clearInterval(data.pollingTicker[`${pollId}`]);
          // }
          if (data.pollingStatus.status !== "STARTING") {
            clearInterval(data.pollingTicker[`${pollId}`]);
            data.pollingTime = 0;
          }
        }, 1000),
      };
    }

    function prepareForPolling(poll) {
      onCountingToEnd(poll.id);
    }

    // Watch pollDetail for getting Answer
    watch(
      () => pollDetail.value.id,
      async () => await getAnswerPolling(),
      {
        immediate: true,
      },
    );

    // Watch answers for getting Stats
    watch(
      () => data.answers,
      value => {
        const stats = {};
        forEach(groupBy(value, "questionId"), (item, key) => {
          stats[key] = {
            ...countBy(item, "answerId"),
            total: item.length,
          };
        });
        data.answerStats = stats;
      },
    );

    // Watch poll status
    watch(
      () => pollDetail,
      async ({ value }) => {
        if (value.status === "DRAFT") {
          data.pollingStatus = {
            status: "READY",
          };
          data.answers = [];
        } else {
          data.pollingStatus = {
            status: value.status === "ACTIVE" ? "STARTING" : "ENDED",
          };
          if (value.status === "ACTIVE") {
            const startTime = moment(value.startTime);
            data.pollingTime = moment().diff(startTime, "seconds");
            prepareForPolling(value);
          }
          await getAnswerPolling();
          data.enableSubscription = true;
        }
        // const startTime = moment(value.startTime);
        // const endTime = moment(value.endTime);
        // const currentTime = moment();
        // if (currentTime.isBetween(startTime, endTime, "seconds")) {
        //   prepareForPolling(value, endTime);
        // } else if (currentTime.isSameOrBefore(startTime, "seconds")) {
        //   data.pollingTime = startTime.diff(moment(), "seconds");
        //   onCountingToEnd(value.id, startTime);
        //   data.pollingStatus = {
        //     status: "READY",
        //     startTime: startTime.format("HH:mm MM/DD/YYYY"),
        //   };

        //   clearInterval(data.pollingTicker[`${value.id}-starting`]);
        //   data.pollingTicker = {
        //     ...data.pollingTicker,
        //     [`${value.id}-starting`]: setInterval(() => {
        //       if (startTime.isSame(moment(), "minutes")) {
        //         clearInterval(data.pollingTicker[value.id]);
        //         prepareForPolling(value, endTime);
        //         clearInterval(data.pollingTicker[`${value.id}-starting`]);
        //       }
        //     }, 1000),
        //   };
        // } else {
        //   data.pollingStatus = {
        //     status: "ENDED",
        //   };
        // }
      },
      {
        immediate: true,
        deep: true,
      },
    );

    watch(
      () => data.pollingTime,
      value => calcTime(value * 1000),
      {
        immediate: true,
      },
    );

    const { onResult } = useSubscription(
      AnswerPollSubcription,
      () => ({
        pollId: pollDetail.value.id,
      }),
      () => ({
        enabled: data.enableSubscription,
      }),
    );
    onResult(({ data: { onUpdatePollAnswerEvent: answer } }) => {
      if (!some(data.answers, item => item.clientId === answer.clientId && item.pollId === answer.pollId && item.questionId === answer.questionId && item.answerId === answer.answerId)) {
        data.answers = [...data.answers, answer];
      }
    });

    onUnmounted(() => {
      forEach(data.pollingTicker, item => clearInterval(item));
      data.pollingTicker = {};
    });

    const updatePolling = async status => {
      try {
        data.isLoading = true;
        await doUpdatePoll(pollDetail.value.eventId, pollDetail.value.id, {
          ...pollDetail.value,
          status,
          startTime: getMomentT(new Date()).toISOString(),
        });
        emit("onUpdate", false);
      } catch (error) {
        console.log({ error });
      } finally {
        data.isLoading = false;
      }
    };

    return {
      data,
      isEmpty,
      get,
      updatePolling,
    };
  },
};
</script>
