<template>
  <div class="mt-4 border p-2 rounded relative">
    <h2 class="absolute -top-3 left-2 bg-white dark:bg-dark-bgSecondary">
      <span v-if="isNew" v-text="`${number + 1}.`" />
      {{ data.customer.email }}
    </h2>
    <DeleteIcon
      v-if="isNew"
      class="bg-white dark:bg-dark-bgSecondary absolute right-2 -top-4 h-5 w-5 md:h-7 md:w-7 ml-2 cursor-pointer text-magenta"
      @click="$emit('onDelete', number)"
    />
    <div class="flex flex-col items-center justify-between mt-2">
      <div
        class="w-full bg-grey-light dark:bg-black-2 border rounded"
        :class="[
          empty(data.customer.email) || !validEmail(data.customer.email)
            ? 'border-danger'
            : 'border-transparent',
        ]"
      >
        <input
          v-model="data.customer.email"
          class="w-full bg-white dark:bg-dark-bgSecondary focus:ring-transparent focus:outline-none focus-visible:outline-none"
          type="email"
          placeholder="Enter email"
        />
      </div>
      <div
        class="w-full bg-grey-light dark:bg-black-2 border rounded mt-2"
        :class="[
          empty(data.customer.firstName)
            ? 'border-danger'
            : 'border-transparent',
        ]"
      >
        <input
          v-model="data.customer.firstName"
          class="w-full bg-white dark:bg-dark-bgSecondary focus:ring-transparent focus:outline-none focus-visible:outline-none"
          type="text"
          placeholder="Enter first name"
        />
      </div>
      <div
        class="w-full bg-grey-light dark:bg-black-2 border rounded mt-2"
        :class="[
          empty(data.customer.lastName)
            ? 'border-danger'
            : 'border-transparent',
        ]"
      >
        <input
          v-model="data.customer.lastName"
          class="w-full bg-white dark:bg-dark-bgSecondary focus:ring-transparent focus:outline-none focus-visible:outline-none"
          type="text"
          placeholder="Enter last name"
        />
      </div>
      <div
        v-if="isNew"
        class="w-full bg-grey-light dark:bg-black-2 border rounded mt-2"
        :class="[
          empty(data.customer.password)
            ? 'border-danger'
            : 'border-transparent',
        ]"
      >
        <input
          v-model="data.customer.password"
          class="w-full bg-white dark:bg-dark-bgSecondary focus:ring-transparent focus:outline-none focus-visible:outline-none"
          type="password"
          placeholder="Enter password"
        />
      </div>
    </div>
  </div>
</template>
<script>
import DeleteIcon from "@Assets/images/DeleteIcon";
import { empty, validEmail } from "@/utils";
import { reactive, watch } from "vue";
export default {
  components: { DeleteIcon },
  props: {
    modelValue: {
      type: Object,
      required: true,
    },
    number: {
      type: Number,
      required: true,
    },
    isNew: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const data = reactive({
      customer: props.modelValue,
    });

    watch(
      () => props.modelValue,
      value => (data.customer = value),
    );
    watch(
      () => data.customer,
      value => emit("update:modelValue", value),
    );
    return {
      data,
      empty,
      validEmail,
    };
  },
};
</script>
