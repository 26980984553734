<template>
  <div v-if="isShow" class="flex fixed top-0 left-0 justify-center w-full h-full z-50">
    <div class="flex min-h-screen min-w-full pt-4 px-4 pb-20 text-center sm:block sm:p-0">
      <div class="fixed inset-0 transition-opacity" aria-hidden="true">
        <div class="absolute inset-0 bg-grey-dark opacity-75"></div>
      </div>

      <!-- This element is to trick the browser into centering the modal contents. -->
      <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true"> &#8203; </span>
      <div
        class="inline-block align-bottom bg-white dark:bg-dark-bgSecondary rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle w-full sm:max-w-xl"
        role="dialog"
        aria-modal="true"
        aria-labelledby="modal-headline"
      >
        <div>
          <div class="flex flex-col">
            <div class="relative bg-magenta py-2">
              <span class="absolute right-2 top-2 cursor-pointer text-black-1" @click="$emit('onClose')">
                <CloseIcon class="h-5 w-5 md:h-7 md:w-7" />
              </span>
              <h3 class="text-lg leading-6 font-medium text-white ml-3" id="modal-headline">
                {{ isNewPoll ? "Create new poll" : isEdit ? `Edit Poll "${pollDetail.name}"` : pollDetail.name }}
              </h3>
            </div>
            <div class="text-center sm:text-left text-gray-900 dark:text-dark-textPrimary">
              <div class="sm:w-full">
                <component :is="isEdit ? 'Poll' : 'PollVoting'" :pollDetail="pollDetail" :isEdit="isEdit" :isNewPoll="isNewPoll" @onUpdate="$emit('onUpdate', $event)" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { empty } from "@/utils";
import CloseIcon from "@Assets/images/CloseIcon";
import Poll from "./Poll.vue";
import PollVoting from "./PollVoting.vue";
export default {
  name: "ConfirmDialog",
  components: {
    CloseIcon,
    Poll,
    PollVoting,
  },
  props: {
    pollDetail: {
      type: Object,
      requried: true,
    },
    isNewPoll: {
      type: Boolean,
      requried: false,
    },
    isEdit: {
      type: Boolean,
      requried: false,
    },
  },
  computed: {
    isShow() {
      return !empty(this.pollDetail);
    },
  },
};
</script>
