<template>
  <div class="mt-2 border p-2 rounded">
    <div v-if="isEdit" class="flex items-center justify-between">
      <label class="rounded inline-flex items-center p-2 bg-off-white-1 dark:bg-dark-bgPrimary cursor-pointer border border-transparent hover:border-magenta">
        <input type="checkbox" class="rounded-full text-magenta focus:ring-magenta" name="radio" v-model="isMultipleAnswer" />
        <span class="ml-2" v-text="'Multiple Answer'" />
      </label>
      <div class="flex items-center">
        <!-- <div class="flex items-center cursor-pointer" @click="onSubmitQuestion">
          <ApproveIcon
            class="h-5 w-5 md:h-7 md:w-7 cursor-pointer text-success"
          />
          <span>Submit</span>
        </div> -->
        <div v-if="isEdited" class="flex flex-col items-center cursor-pointer mr-2 text-magenta" @click="onResetData">
          <CloseIcon class="h-5 w-5 md:h-7 md:w-7" />
          <span>Reset</span>
        </div>

        <div class="flex flex-col items-center cursor-pointer text-danger" @click="onDeleteQuestion">
          <DeleteIcon class="h-5 w-5 md:h-7 md:w-7" />
          <span>Delete</span>
        </div>
      </div>
    </div>
    <div class="flex items-center justify-between mt-2">
      <div v-if="isEdit" class="flex items-center w-full bg-grey-light dark:bg-black-2 border pl-2 rounded" :class="[isEmpty(questionName) ? 'border-danger' : 'border-transparent']">
        <span v-text="`${number + 1}.`" />
        <input class="p-1 w-full bg-transparent focus:ring-transparent focus:outline-none focus-visible:outline-none" placeholder="Enter question" v-model="questionName" />
      </div>
      <h3 v-else v-text="`${number + 1}. ${question.name}`" class="block capitalize tracking-wide text-xs md:text-base font-montserrat font-semibold pt-2.5" />
      <div v-if="isEdit" class="flex items-center justify-between">
        <AddIcon class="h-5 w-5 md:h-7 md:w-7 ml-2 cursor-pointer text-magenta" @click="onCreateAnswer" />
        <!-- <DeleteIcon
          class="h-5 w-5 md:h-7 md:w-7 ml-2 cursor-pointer text-magenta"
          @click="onDeleteQuestion"
        /> -->
      </div>
    </div>
    <div class="flex flex-col">
      <template v-if="isEdit">
        <label
          class="rounded mt-1 flex flex-col py-2 bg-off-white-1 dark:bg-dark-bgPrimary border border-transparent"
          :class="[!isEdit && 'cursor-pointer hover:border-magenta']"
          v-for="(answer, index) in question.answers"
          :key="index"
        >
          <div class="w-full flex items-center justify-between">
            <input
              class="ml-1 p-1 w-full bg-grey-light dark:bg-black-2 border focus:ring-transparent focus:outline-none focus-visible:outline-none rounded"
              placeholder="Enter answer"
              v-model="answer.answer"
              :class="[isEmpty(answer.answer) ? 'border-danger' : 'border-transparent']"
            />
            <DeleteIcon class="h-5 w-5 md:h-7 md:w-7 ml-2 cursor-pointer text-magenta" @click="onDeleteAnswer(answer, index)" />
          </div>
        </label>
      </template>

      <div v-else class="relative p-1 bg-off-white-1 dark:bg-dark-bgPrimary border border-transparent mb-2" v-for="(answer, index) in question.answers" :key="index">
        <div class="flex items-center justify-between z-10 relative">
          <span class="ml-2" v-text="`${index + 1}. ${answer.answer}`" />
          <span v-text="`${round(((get(stats, answer.id, 0) || 0) / get(stats, `total`, 0)) * 100, 2) || 0}%`" />
        </div>
        <div class="absolute top-0 left-0 w-full h-full overflow-hidden text-xs flex rounded bg-bgPrimary">
          <div
            :style="`width: ${round(((get(stats, answer.id, 0) || 0) / get(stats, `total`, 0)) * 100, 2) || 0}%`"
            class="z-0 shadow-none flex flex-col text-center whitespace-nowrap text-black justify-center bg-magenta"
          ></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import ApproveIcon from "@Assets/images/ApproveIcon";
import CloseIcon from "@Assets/images/CloseIcon";
import DeleteIcon from "@Assets/images/DeleteIcon";
import AddIcon from "@Assets/images/AddIcon";
import { cloneDeep, isEqual, isEmpty, every, get, round } from "lodash";
export default {
  components: {
    // ApproveIcon,
    DeleteIcon,
    CloseIcon,
    AddIcon,
  },
  props: {
    modelValue: {
      type: String,
      required: true,
    },
    question: {
      type: Object,
      required: true,
    },
    number: {
      type: Number,
      required: true,
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
    stats: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    isEdited() {
      return !isEqual(this.cachedQuestion, this.question);
    },
  },
  data: () => ({
    isMultipleAnswer: false,
    cachedQuestion: {},
    questionName: "",
  }),
  watch: {
    question: {
      handler(value) {
        this.questionName = value.name;
        this.isMultipleAnswer = value.isMultiple;
      },
      immediate: true,
      deep: true,
    },
    isMultipleAnswer(value) {
      this.$emit("onUpdate", [this.question, this.number, "isMultiple", value]);
    },
    questionName(value) {
      // this.$emit("onUpdate", [this.question, this.number, "name", value]);
      this.$emit("update:modelValue", value);
    },
  },
  mounted() {
    this.cachedQuestion = cloneDeep(this.question);
    this.questionName = this.question.name;
  },
  methods: {
    isEmpty,
    round,
    get,
    onDeleteAnswer(answer, indexAnswer) {
      this.$emit("onUpdate", [this.question, this.number, "DELETE_ANSWER", indexAnswer]);
    },
    onResetData() {
      this.$emit("onUpdate", [this.question, this.number, "RESET_ANSWER", cloneDeep(this.cachedQuestion)]);
    },
    onDeleteQuestion() {
      this.$emit("onUpdate", [this.question, this.number, "DELETE_QUESTION"]);
    },
    onCreateAnswer() {
      if (every(this.question.answers, ans => !isEmpty(ans.answer))) {
        this.$emit("onUpdate", [this.question, this.number, "ADD_ANSWER"]);
      }
    },
  },
};
</script>
<style lang="css" scoped>
/* [type="checkbox"]:checked {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3ccircle cx='8' cy='8' r='3'/%3e%3c/svg%3e");
} */
</style>
