<template>
  <div v-if="isShow" class="flex fixed top-0 left-0 justify-center w-full h-full z-50">
    <div class="flex min-h-screen min-w-full pt-4 px-4 pb-20 text-center sm:block sm:p-0">
      <div class="fixed inset-0 transition-opacity" aria-hidden="true">
        <div class="absolute inset-0 bg-grey-dark opacity-75"></div>
      </div>

      <!-- This element is to trick the browser into centering the modal contents. -->
      <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true"> &#8203; </span>
      <div
        class="inline-block align-bottom bg-white dark:bg-dark-bgSecondary rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle w-full sm:max-w-xl"
        role="dialog"
        aria-modal="true"
        aria-labelledby="modal-headline"
      >
        <div>
          <div class="flex flex-col">
            <div class="relative bg-magenta py-2">
              <span class="absolute right-2 top-2 cursor-pointer text-black-1" @click="$emit('close')">
                <CloseIcon class="h-5 w-5 md:h-7 md:w-7" />
              </span>
              <h3 class="text-lg leading-6 font-medium text-white ml-3" id="modal-headline">Error!!!!</h3>
            </div>
            <div class="text-center sm:text-left text-gray-900 dark:text-dark-textPrimary">
              <div class="sm:w-full p-1">
                <p class="text-center my-3 text-magenta">Opps!!! You have been uploaded wrong excel's format.</p>
                <p class="text-center my-2">Make sure that data imported from Excel as same as format below including the header</p>
                <table class="text-left w-full table-auto border">
                  <thead>
                    <tr>
                      <th class="border text-xs md:text-sm text-grey-mid font-bold p-1">
                        <span>firstName</span>
                      </th>
                      <th class="border text-xs md:text-sm text-grey-mid font-bold p-1">
                        <span>lastName</span>
                      </th>
                      <th class="border text-xs md:text-sm text-grey-mid font-bold p-1">
                        <span>email</span>
                      </th>
                      <th class="border text-xs md:text-sm text-grey-mid font-bold p-1">
                        <span>password</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td class="border text-left text-xs md:text-sm lg:text-base text-black-1 dark:text-dark-textPrimary font-medium px-1 py-2">first name</td>
                      <td class="border text-xs md:text-sm lg:text-base text-black-1 dark:text-dark-textPrimary font-medium px-1 py-2">last name</td>
                      <td class="border text-xs md:text-sm lg:text-base text-black-1 dark:text-dark-textPrimary font-medium px-1 py-2">email@gmail.com</td>
                      <td class="border text-xs md:text-sm lg:text-base font-medium px-1 py-2">password</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CloseIcon from "@Assets/images/CloseIcon";
export default {
  name: "ConfirmDialog",
  components: {
    CloseIcon,
  },
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
