<template>
  <div>
    <input ref="excel-upload-input" type="file" accept=".xlsx, .xls, .csv" class="hidden z-0" @change="handleClick" />
    <div class="flex flex-col items-center cursor-pointer text-success" @click="handleUpload">
      <FileIcon class="h-5 w-5 md:h-7 md:w-7" />
      Import
    </div>
  </div>
</template>

<script>
import XLSX from "xlsx";
import FileIcon from "@Assets/images/FileIcon";

export default {
  components: {
    FileIcon,
  },
  data() {
    return {
      excelData: {
        header: null,
        results: null,
      },
    };
  },
  watch: {
    loading(value) {
      this.$emit("loading", value);
    },
  },
  methods: {
    generateData({ header, results }) {
      this.excelData.header = header;
      this.excelData.results = results;
      this.$emit("onSuccess", this.excelData);
    },

    handleUpload() {
      this.$refs["excel-upload-input"].click();
    },
    handleClick(e) {
      const files = e.target.files;
      const rawFile = files[0]; // only use files[0]
      if (!rawFile) return;
      this.upload(rawFile);
    },
    upload(rawFile) {
      this.$refs["excel-upload-input"].value = null; // fix can't select the same excel
      this.readerData(rawFile);
    },
    readerData(rawFile) {
      this.$store.dispatch("updateLoadingText", "Importing data");
      this.$store.dispatch("toggleLoading");
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = e => {
          const data = e.target.result;
          const workbook = XLSX.read(data, { type: "array" });
          const firstSheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[firstSheetName];
          const header = this.getHeaderRow(worksheet);
          const results = XLSX.utils.sheet_to_json(worksheet);
          this.generateData({ header, results });
          resolve();
        };
        reader.readAsArrayBuffer(rawFile);
      });
    },
    getHeaderRow(sheet) {
      const headers = [];
      const range = XLSX.utils.decode_range(sheet["!ref"]);
      let C;
      const R = range.s.r;
      /* start in the first row */
      for (C = range.s.c; C <= range.e.c; ++C) {
        /* walk every column in the range */
        const cell = sheet[XLSX.utils.encode_cell({ c: C, r: R })];
        /* find the cell in the first row */
        let hdr = "UNKNOWN " + C; // <-- replace with your desired default
        if (cell && cell.t) hdr = XLSX.utils.format_cell(cell);
        headers.push(hdr);
      }
      return headers;
    },
  },
};
</script>
