<template>
  <Form v-slot="{ errors }" :validation-schema="schema" class="flex flex-col font-fs-elliot-pro py-5 mb-8 md:mb-0" @submit="onSubmit">
    <div class="flex flex-col md:flex-row md:mb-7 lg:mb-5">
      <div class="w-1/2 md:w-1/3 lg:w-1/6">
        <label class="block capitalize tracking-wide text-grey-mid text-xs md:text-base font-montserrat font-semibold pt-2.5"> Link 1: </label>
      </div>
      <div class="grid grid-cols-2 gap-4 w-full md:w-2/3">
        <div>
          <label>Link 1 Text</label>
          <Field
            name="bidUrl.name"
            v-model="currentEvent.bidUrl.name"
            type="text"
            class="block w-full appearance-none bg-off-white-1 dark:bg-dark-bgPrimary text-xs md:text-base text-black-1 dark:text-dark-textPrimary border border-grey-mid focus:ring-magenta focus:border-magenta rounded py-2 md:py-3 px-2 md:px-4 mb-3"
          />
        </div>

        <div>
          <label>Link 1 URL</label>
          <Field
            name="bidUrl.url"
            v-model="currentEvent.bidUrl.url"
            type="text"
            :class="{
              'text-danger border border-danger': errors['bidUrl.url'],
            }"
            class="block w-full appearance-none bg-off-white-1 dark:bg-dark-bgPrimary text-xs md:text-base text-black-1 dark:text-dark-textPrimary border border-grey-mid focus:ring-magenta focus:border-magenta rounded py-2 md:py-3 px-2 md:px-4 mb-3"
          />
          <p class="text-danger dark:text-danger text-base italic">
            {{ errors["bidUrl.url"] }}
          </p>
        </div>
      </div>
    </div>

    <div class="flex flex-col md:flex-row md:mb-7 lg:mb-5">
      <div class="w-1/2 md:w-1/3 lg:w-1/6">
        <label class="block capitalize tracking-wide text-grey-mid text-xs md:text-base font-montserrat font-semibold pt-2.5"> Link 2: </label>
      </div>
      <div class="grid grid-cols-2 gap-4 w-full md:w-2/3">
        <div>
          <label>Link 2 Text</label>
          <Field
            name="donateUrl.name"
            v-model="currentEvent.donateUrl.name"
            type="text"
            class="block w-full appearance-none bg-off-white-1 dark:bg-dark-bgPrimary text-xs md:text-base text-black-1 dark:text-dark-textPrimary border border-grey-mid focus:ring-magenta focus:border-magenta rounded py-2 md:py-3 px-2 md:px-4 mb-3"
          />
        </div>

        <div>
          <label>Link 2 URL</label>
          <Field
            name="donateUrl.url"
            v-model="currentEvent.donateUrl.url"
            type="text"
            :class="{
              'text-danger border border-danger': errors['donateUrl.url'],
            }"
            class="block w-full appearance-none bg-off-white-1 dark:bg-dark-bgPrimary text-xs md:text-base text-black-1 dark:text-dark-textPrimary border border-grey-mid focus:ring-magenta focus:border-magenta rounded py-2 md:py-3 px-2 md:px-4 mb-3"
          />
          <p class="text-danger dark:text-danger text-base italic">
            {{ errors["donateUrl.url"] }}
          </p>
        </div>
      </div>
    </div>

    <div class="flex flex-col md:flex-row md:mb-7 lg:mb-5">
      <div class="w-1/2 md:w-1/3 lg:w-1/6">
        <label class="block capitalize tracking-wide text-grey-mid text-xs md:text-base font-montserrat font-semibold pt-2.5"> Link 3: </label>
      </div>
      <div class="grid grid-cols-2 gap-4 w-full md:w-2/3">
        <div>
          <label>Link 3 Text</label>
          <Field
            name="buyTicketUrl.name"
            v-model="currentEvent.buyTicketUrl.name"
            type="text"
            class="block w-full appearance-none bg-off-white-1 dark:bg-dark-bgPrimary text-xs md:text-base text-black-1 dark:text-dark-textPrimary border border-grey-mid focus:ring-magenta focus:border-magenta rounded py-2 md:py-3 px-2 md:px-4 mb-3"
          />
        </div>

        <div>
          <label>Link 3 URL</label>
          <Field
            name="buyTicketUrl.url"
            v-model="currentEvent.buyTicketUrl.url"
            type="text"
            :class="{
              'text-danger border border-danger': errors['buyTicketUrl.url'],
            }"
            class="block w-full appearance-none bg-off-white-1 dark:bg-dark-bgPrimary text-xs md:text-base text-black-1 dark:text-dark-textPrimary border border-grey-mid focus:ring-magenta focus:border-magenta rounded py-2 md:py-3 px-2 md:px-4 mb-3"
          />
          <p class="text-danger dark:text-danger text-base italic">
            {{ errors["buyTicketUrl.url"] }}
          </p>
        </div>
      </div>
    </div>

    <div class="flex flex-col md:flex-row md:mb-7 lg:mb-5">
      <div class="md:w-1/3 lg:w-1/6">
        <label class="block capitalize tracking-wide text-grey-mid text-xs md:text-base font-montserrat font-semibold pt-2.5"> Breakout Rooms: </label>
      </div>
      <div class="w-full md:w-2/3">
        <Conference :conferenceList="conferenceData" @onCreate="onCreateConference" @onDelete="onDeleteConference" @onEdit="onEditConference" />
      </div>
    </div>

    <div class="flex flex-col md:flex-row md:mb-7 lg:mb-5">
      <div class="md:w-1/3 lg:w-1/6">
        <label class="block capitalize tracking-wide text-grey-mid text-xs md:text-base font-montserrat font-semibold pt-2.5"> Video Advertisements: </label>
      </div>
      <div class="w-full md:w-2/3">
        <p class="font-montserrat text-sm italic mb-4">Currently, we support <b>youtube embed video, vimeo, video.isilive.ca</b></p>
        <VideoUrl :preVideoList="preVideoData" @onCreate="onCreatePreVideo" @onDelete="onDeletePreVideo" @onEdit="onEditPreVideo" />
      </div>
    </div>

    <div class="flex flex-col md:flex-row md:mb-7 lg:mb-8">
      <div class="w-1/2 md:w-1/3 lg:w-1/6">
        <label for="event-youtubeURL" class="block capitalize tracking-wide text-grey-mid text-xs md:text-base font-montserrat font-semibold pt-2.5"> Streaming URL: </label>
      </div>
      <div class="w-full md:w-2/3 my-2 md:my-0">
        <Field
          id="event-youtubeURL"
          v-model="currentEvent.youtubeURL"
          name="youtubeURL"
          type="text"
          :class="{
            'text-danger border border-danger': errors.name,
          }"
          class="block w-full appearance-none bg-off-white-1 dark:bg-dark-bgPrimary text-xs md:text-base focus:ring-magenta focus:border-magenta rounded py-2 md:py-3 px-2 md:px-4 mb-3"
        />
        <p class="text-danger dark:text-danger text-base italic">
          {{ errors.youtubeURL }}
        </p>
      </div>
    </div>

    <!-- <div class="flex mb-7 lg:mb-5 hidden">
      <div class="w-1/2 md:w-1/3 lg:w-1/6">
        <label class="block capitalize tracking-wide text-grey-mid text-xs md:text-base font-montserrat font-semibold pt-2.5"> Leaderboard URL(s): </label>
      </div>
      <div class="w-2/3 md:w-1/3 lg:w-2/3">
        <div class="grid grid-cols-2 gap-4">
          <div>
            <Field
              name="leaderBoardUrl.name"
              v-model="currentEvent.leaderBoardUrl.name"
              type="text"
              class="block w-full appearance-none bg-off-white-1 dark:bg-dark-bgPrimary text-xs md:text-base text-black-1 dark:text-dark-textPrimary border border-grey-mid focus:ring-magenta focus:border-magenta rounded py-2 md:py-3 px-2 md:px-4 mb-3"
            />
          </div>

          <div>
            <Field
              name="leaderBoardUrl.url"
              v-model="currentEvent.leaderBoardUrl.url"
              type="text"
              :class="{
                'text-danger border border-danger': errors['leaderBoardUrl.url'],
              }"
              class="block w-full appearance-none bg-off-white-1 dark:bg-dark-bgPrimary text-xs md:text-base text-black-1 dark:text-dark-textPrimary border border-grey-mid focus:ring-magenta focus:border-magenta rounded py-2 md:py-3 px-2 md:px-4 mb-3"
            />
            <p class="text-danger dark:text-danger text-base italic">
              {{ errors["leaderBoardUrl.url"] }}
            </p>
          </div>
        </div>
        <div class="md:hidden">
          <button class="bg-transparent text-xs text-magenta font-bold inline-flex items-center border-none overflow-hidden focus:outline-none">
            <AddIcon class="w-6 h-6 mr-1 lg:mr-2" />
            <span>Add Another</span>
          </button>
        </div>
      </div>
      <div class="hidden md:block md:w-1/3 lg:w-1/6">
        <button class="bg-transparent text-base lg:text-xl text-magenta font-bold inline-flex items-center border-none overflow-hidden focus:outline-none py-3 px-4">
          <AddIcon class="w-6 h-6 mr-1 lg:mr-2" />
          <span>Add Another</span>
        </button>
      </div>
    </div> -->

    <!-- <div class="flex mb-7 lg:mb-5 hidden">
      <div class="w-1/2 md:w-1/3 lg:w-1/6">
        <label for="activity-ticker-url" class="block capitalize tracking-wide text-grey-mid text-xs md:text-base font-montserrat font-semibold pt-2.5"> Activity Ticker URL: </label>
      </div>
      <div class="w-2/3">
        <div class="grid grid-cols-2 gap-4">
          <div>
            <Field
              name="activityTickerUrl.name"
              v-model="currentEvent.activityTickerUrl.name"
              type="text"
              class="
                block
                w-full
                appearance-none
                bg-off-white-1
                dark:bg-dark-bgPrimary
                text-xs
                md:text-base
                text-black-1
                dark:text-dark-textPrimary
                border border-grey-mid
                focus:ring-magenta focus:border-magenta
                rounded
                py-2
                md:py-3
                px-2
                md:px-4
                mb-3
              "
            />
          </div>

          <div>
            <Field
              name="activityTickerUrl.url"
              v-model="currentEvent.activityTickerUrl.url"
              type="text"
              :class="{
                'text-danger border border-danger': errors['activityTickerUrl.url'],
              }"
              class="
                block
                w-full
                appearance-none
                bg-off-white-1
                dark:bg-dark-bgPrimary
                text-xs
                md:text-base
                text-black-1
                dark:text-dark-textPrimary
                border border-grey-mid
                focus:ring-magenta focus:border-magenta
                rounded
                py-2
                md:py-3
                px-2
                md:px-4
                mb-3
              "
            />
            <p class="text-danger dark:text-danger text-base italic">
              {{ errors["activityTickerUrl.url"] }}
            </p>
          </div>
        </div>
      </div>
    </div> -->

    <!-- <div class="flex mb-7 lg:mb-5 hidden">
      <div class="w-1/2 md:w-1/3 lg:w-1/6">
        <label class="block capitalize tracking-wide text-grey-mid text-xs md:text-base font-montserrat font-semibold pt-2.5"> Totaliser Ticket URL: </label>
      </div>
      <div class="w-2/3">
        <div class="grid grid-cols-2 gap-4">
          <div>
            <Field
              name="totalIserTickerUrl.name"
              v-model="currentEvent.totalIserTickerUrl.name"
              type="text"
              class="block w-full appearance-none bg-off-white-1 dark:bg-dark-bgPrimary text-xs md:text-base text-black-1 dark:text-dark-textPrimary border border-grey-mid focus:ring-magenta focus:border-magenta rounded py-2 md:py-3 px-2 md:px-4 mb-3"
            />
          </div>

          <div>
            <Field
              name="totalIserTickerUrl.url"
              v-model="currentEvent.totalIserTickerUrl.url"
              type="text"
              :class="{
                'text-danger border border-danger': errors['totalIserTickerUrl.url'],
              }"
              class="block w-full appearance-none bg-off-white-1 dark:bg-dark-bgPrimary text-xs md:text-base text-black-1 dark:text-dark-textPrimary border border-grey-mid focus:ring-magenta focus:border-magenta rounded py-2 md:py-3 px-2 md:px-4 mb-3"
            />
            <p class="text-danger dark:text-danger text-base italic">
              {{ errors["totalIserTickerUrl.url"] }}
            </p>
          </div>
        </div>
      </div>
    </div> -->

    <button
      type="submit"
      class="button_large-Alternative absolute right-0 bottom-0 md:bottom-auto md:-top-8 md:-right-8 uppercase bg-magenta border rounded-full py-3 lg:py-4 lg:px-8 focus:outline-none"
    >
      Save
    </button>
  </Form>
</template>

<script>
import { computed, reactive } from "vue";

import { Form, Field } from "vee-validate";
import * as Yup from "yup";
import * as _ from "lodash";

import { sleep } from "@Helpers/sleep";
import { putEvent } from "@API/event/event-repository";

// import AddIcon from "@Assets/images/AddIcon";
import { empty, isValidUrl } from "@/utils";

import Conference from "@/components/pages/event-detail/Conference";
import VideoUrl from "@/components/pages/event-detail/VideoUrl";

export default {
  name: "ContentTab",
  props: {
    eventDetail: {
      type: Object,
      required: true,
    },
  },
  components: {
    Form,
    Field,
    // AddIcon,
    Conference,
    VideoUrl,
  },
  emits: ["toggleLoading", "onUpdate"],
  setup(props) {
    const currentEvent = computed(() => props.eventDetail);
    const conferenceData = reactive(
      empty(currentEvent.value.options)
        ? []
        : _.map(currentEvent.value.options, item => ({
            ...item,
            isNew: false,
            isEdit: false,
          })),
    );
    if (empty(conferenceData)) {
      onCreateConference();
    }

    function onCreateConference() {
      _.forEach(conferenceData, (item, index) => {
        conferenceData[index] = {
          ...item,
          isEdit: false,
        };
      });
      conferenceData.push({
        url: "",
        title: "",
        pinCode: "",
        isNew: true,
        isEdit: true,
      });
    }
    function onDeleteConference(conferenceIndex) {
      conferenceData.splice(conferenceIndex, 1);
      if (empty(conferenceData)) {
        onCreateConference();
      }
    }

    function onEditConference([conferenceIndex, data]) {
      conferenceData[conferenceIndex] = {
        ...data,
        isEdit: !data.isEdit,
      };
    }

    const preVideoData = reactive(
      empty(currentEvent.value.preVideos)
        ? []
        : _.map(currentEvent.value.preVideos, item => ({
            url: item,
            isNew: false,
            isEdit: false,
          })),
    );
    if (empty(preVideoData)) {
      onCreatePreVideo();
    }

    function onCreatePreVideo() {
      _.forEach(preVideoData, (item, index) => {
        preVideoData[index] = {
          ...item,
          isEdit: false,
        };
      });
      preVideoData.push({
        url: "",
        isNew: true,
        isEdit: true,
      });
    }
    function onDeletePreVideo(preVideoIndex) {
      preVideoData.splice(preVideoIndex, 1);
      if (empty(preVideoData)) {
        onCreatePreVideo();
      }
    }

    function onEditPreVideo([preVideoIndex, data]) {
      preVideoData[preVideoIndex] = {
        ...data,
        isEdit: !data.isEdit,
      };
    }

    const schema = Yup.object().shape({
      name: Yup.string(),
      eventDate: Yup.string(),
      eventTime: Yup.string(),
      youtubeURL: Yup.string().url("Enter correct url").required("Streaming URL is required"),
      virtualEventUrl: Yup.object().shape({
        url: Yup.string().url("Enter correct url").nullable(),
      }),
      fundraisingSiteUrl: Yup.object().shape({
        url: Yup.string().url("Enter correct url").nullable(),
      }),
      bidUrl: Yup.object().shape({
        url: Yup.string().url("Enter correct url").nullable(),
      }),
      donateUrl: Yup.object().shape({
        url: Yup.string().url("Enter correct url").nullable(),
      }),
      buyTicketUrl: Yup.object().shape({
        url: Yup.string().url("Enter correct url").nullable(),
      }),
    });
    return {
      currentEvent,
      schema,
      conferenceData,
      onCreateConference,
      onDeleteConference,
      onEditConference,
      preVideoData,
      onCreatePreVideo,
      onDeletePreVideo,
      onEditPreVideo,
    };
  },
  methods: {
    async onSubmit() {
      try {
        let breakoutRooms = _.map(this.conferenceData, item => _.omit(item, ["isEdit", "isNew"]));
        breakoutRooms = _.filter(breakoutRooms, item => !empty(item.title));

        const preVideos = _.map(
          _.filter(this.preVideoData, item => !empty(item.url)),
          item => item.url,
        );
        const payload = {
          ...this.currentEvent,
          options: breakoutRooms,
          preVideos,
        };
        this.$store.dispatch("updateLoadingText", "Updating event");
        this.$emit("toggleLoading");

        _.forOwn(payload, (value, key) => {
          if (key.includes("Url") && (!value.url.length || value.url === " "))
            // console.log(key);
            delete payload[key];
        });

        console.log({ payload });
        const result = await putEvent(payload);
        if (result.success) {
          this.$emit("onUpdate");
        }

        // Show Alert
        this.$store.dispatch("toggleAlert", {
          isAlert: true,
          status: result.success,
          title: result.success ? "Success!" : "Failed!",
          message: result.message,
        });
      } catch (error) {
        console.log({ error });
      } finally {
        this.$emit("toggleLoading", false);

        await sleep(5000);
        // Hide Alert
        this.$store.dispatch("toggleAlert", {
          isAlert: false,
        });
      }
    },
  },
};
</script>
