import gql from "graphql-tag";

const CommentCreatedSubcription = gql`
  subscription MySubscription($eventId: String!) {
    onCreateComment(eventId: $eventId) {
      content
      createdDate
      email
      eventId
      id
      lastUpdatedDate
      name
      status
    }
  }
`;

const CommentUpdatedSubcription = gql`
  subscription MySubscription($eventId: String!) {
    onUpdateComment(eventId: $eventId) {
      content
      createdDate
      email
      eventId
      id
      lastUpdatedDate
      name
      status
    }
  }
`;

export { CommentCreatedSubcription, CommentUpdatedSubcription };
