import gql from "graphql-tag";

const MessagesSubcription = gql`
  subscription MySubscription($conversationId: String!) {
    onAddMessage(conversationId: $conversationId) {
      body
      conversationId
      createdAt
      id
      sender
      userName
    }
  }
`;

export default MessagesSubcription;
