<template>
  <div class="p-4">
    <div class="flex flex-col-reverse md:flex-row items-center justify-between py-2">
      <span class="text-danger text-left w-full md:w-7/12">
        {{ isInvalidInput ? "Please input data" : "" }}
      </span>
      <div class="flex items-center justify-end w-full md:w-5/12">
        <template v-if="isNew">
          <UploadExcel @onSuccess="onGetDataFromExcel" />
          <div class="flex flex-col items-center mx-4" :class="[isInvalidInput ? 'cursor-not-allowed text-grey' : 'cursor-pointer text-success']" @click="onAddCustomer">
            <AddIcon class="h-5 w-5 md:h-7 md:w-7" />
            Add
          </div>
        </template>
        <div class="flex flex-col items-center" :class="[isInvalidInput ? 'cursor-not-allowed text-grey' : 'cursor-pointer text-magenta']" @click="onSubmitCustomer(false)">
          <ApproveIcon class="h-5 w-5 md:h-7 md:w-7" />
          {{ isNew ? "SUBMIT" : "UPDATE" }}
        </div>
      </div>
    </div>

    <div class="flex flex-col font-medium max-h-screen-2/3 overflow-y-auto text-left p-1">
      <Customer v-for="(_, index) in customerList" v-model="customerList[index]" :key="index" :number="index" :isNew="isNew" @onDelete="onDeleteCustomer" />
    </div>
  </div>
</template>
<script>
import AddIcon from "@Assets/images/AddIcon";
import ApproveIcon from "@Assets/images/ApproveIcon";
import { set, some, difference, intersectionBy, chunk, map, isArrayLikeObject, every } from "lodash";
import { addCustomer, updateCustomer } from "@API/event/event-repository";
import { empty, validEmail } from "@/utils";
import Customer from "./Customer";
import UploadExcel from "@/components/layouts/UploadExcel";
import { sleep } from "@Helpers/sleep";
export default {
  props: {
    customer: {
      type: Object,
      required: true,
    },
    isNew: {
      type: Boolean,
      default: false,
    },
    customerListStored: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    AddIcon,
    ApproveIcon,
    Customer,
    UploadExcel,
  },
  computed: {
    isInvalidInput() {
      return some(this.customerList, item => empty(item.firstName) || empty(item.lastName) || empty(item.email) || !validEmail(item.email) || (this.isNew && empty(item.password)));
    },
  },
  data: () => ({
    customerList: [],
  }),
  watch: {
    customer: {
      handler(value) {
        this.customerList = [value];
      },
      immediate: true,
    },
  },

  beforeUnmount() {
    this.emitter.all.delete("submit-confirm-dialog-customer-add");
  },

  mounted() {
    // Listen header input change text
    this.emitter.on("submit-confirm-dialog-customer-add", async () => {
      const { type } = this.$store.state.auth.confirmDialog.confirmAction;
      switch (type) {
        case "SUBMIT_WITH_DUPLICATED":
          this.$store.dispatch("resetConfirmDialog");
          await this.onSubmitCustomer(true);
          break;
      }
    });
  },
  methods: {
    empty,
    onAddCustomer() {
      if (this.isNew && !this.isInvalidInput) {
        this.customerList.push({});
      }
    },
    onDeleteCustomer(cusIndex) {
      if (cusIndex === 0 && this.customerList.length === 1) {
        this.customerList = [{}];
      } else {
        this.customerList.splice(cusIndex, 1);
      }
    },
    async onGetDataFromExcel({ header, results }) {
      try {
        await sleep(500);
        if (empty(difference(["firstName", "email", "lastName", "password"], header))) {
          this.customerList = results;
          this.$store.dispatch("toggleAlert", {
            isAlert: true,
            status: true,
            title: "Successfully",
            message: "Imported customers from Excel.",
          });
        } else {
          this.$store.dispatch("toggleAlert", {
            isAlert: true,
            status: false,
            title: "Error",
            message: "Customers imported from Excel wrong format.",
          });
          this.$emit("onNotice");
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.$store.dispatch("closeLoading");
        await sleep(5000);
        // Hide Alert
        this.$store.dispatch("toggleAlert", {
          isAlert: false,
        });
      }
    },
    doAddCustomer() {
      const splitData = chunk(this.customerList, 100);
      return Promise.all(map(splitData, item => addCustomer(this.$route.params.eventId, item)));
    },
    async onSubmitCustomer(isForce = false) {
      if (!this.isInvalidInput) {
        try {
          let result;
          if (this.isNew) {
            if (isForce) {
              this.$store.dispatch("updateLoadingText", "Creating customers");
              this.$store.dispatch("toggleLoading");
              result = await this.doAddCustomer();
            } else {
              const dataDup = intersectionBy(this.customerList, this.customerListStored, "email");
              if (!empty(dataDup)) {
                this.$store.dispatch("toggleConfirmDialog", {
                  eventListen: "submit-confirm-dialog-customer-add",
                  isConfirmDialog: true,
                  title: "Submit",
                  confirmButtonText: "OK",
                  message: "Do you wanna submit customer data with duplicated customer?",
                  confirmAction: {
                    type: "SUBMIT_WITH_DUPLICATED",
                  },
                });
              } else {
                this.$store.dispatch("updateLoadingText", "Creating customers");
                this.$store.dispatch("toggleLoading");
                result = await this.doAddCustomer();
              }
            }
          } else {
            this.$store.dispatch("updateLoadingText", "Upding customer");
            this.$store.dispatch("toggleLoading");
            const customer = this.customerList[0];
            const { email, lastName, firstName, id } = customer;
            result = await updateCustomer(customer.eventId, {
              email,
              lastName,
              firstName,
              id,
            });
          }
          if (!empty(result)) {
            this.$emit("onUpdate");
            if (!isArrayLikeObject(result)) {
              this.$store.dispatch("toggleAlert", {
                isAlert: true,
                status: result.success,
                title: result.success ? "Success!" : "Failed!",
                message: result.message,
              });
            } else {
              const isSuccess = every(result, item => item.success);
              this.$store.dispatch("toggleAlert", {
                isAlert: true,
                status: isSuccess,
                title: isSuccess ? "Success!" : "Failed!",
                message: isSuccess ? "Imported customers successful!" : "Imported customers failed!",
              });
            }
          }
        } catch (error) {
          console.log({ error });
        } finally {
          this.$store.dispatch("closeLoading");
          await sleep(5000);
          // Hide Alert
          this.$store.dispatch("toggleAlert", {
            isAlert: false,
          });
        }
      }
    },
  },
};
</script>
<style >
.dp__input_icon {
  left: initial;
  right: 20px;
}
.dp__input_icon_pad {
  padding-left: 10px;
}
.dp__input {
  background-color: transparent;
  border: none;
}
</style>
