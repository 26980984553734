<template>
  <div>
    <div class="block md:flex justify-end items-center">
      <button class="button_small-Alternative uppercase bg-magenta border rounded-full py-3 px-4 lg:py-4 lg:px-8 focus:outline-none" @click="doCreatePoll">New Poll</button>
    </div>
    <Popup :pollDetail="data.pollDetail" :isNewPoll="data.isNewPoll" :isEdit="data.isEditPoll" @onClose="data.pollDetail = {}" @onUpdate="doUpdatePoll" />
    <Field
      v-if="!data.isLoading && data.pollList.length > 0"
      v-model="data.searchInfo"
      @input="debounceSearchData"
      type="text"
      name="search"
      placeholder="Input poll info to search"
      class="mt-4 block w-full appearance-none bg-off-white-1 dark:bg-dark-bgPrimary text-xs md:text-base focus:ring-magenta focus:border-magenta rounded py-2 md:py-3 px-2 md:px-4 mb-3"
    />
    <p v-if="data.pollListDataTable?.length === 0" class="w-full text-base lg:text-xl font-semibold text-magenta text-center my-5 font-fs-elliot-pro">No poll</p>
    <div v-else class="w-full">
      <table class="text-left w-full">
        <thead>
          <tr>
            <th class="text-xs md:text-sm text-grey-mid font-bold cursor-pointer">
              <div class="flex items-center relative p-2.5 md:p-5 lg:py-3.5 lg:px-7">
                <span>Name</span>
              </div>
            </th>
            <th class="text-xs md:text-sm text-grey-mid font-bold cursor-pointer">
              <div class="flex items-center relative p-2.5 md:p-5 lg:py-3.5 lg:px-7">
                <span>Status</span>
              </div>
            </th>
            <th class="text-xs md:text-sm text-grey-mid font-bold cursor-pointer w-12">
              <div class="flex items-center relative p-2.5 md:p-5 lg:py-3.5 lg:px-7">
                <span>Result</span>
              </div>
            </th>
            <th class="text-xs md:text-sm text-grey-mid font-bold cursor-pointer w-12">
              <div class="flex items-center relative p-2.5 md:p-5 lg:py-3.5 lg:px-7">
                <span>Edit</span>
              </div>
            </th>
            <th class="text-xs md:text-sm text-grey-mid font-bold cursor-pointer w-12">
              <div class="flex items-center relative p-2.5 md:p-5 lg:py-3.5 lg:px-7">
                <span>Report</span>
              </div>
            </th>
            <th class="text-xs md:text-sm text-grey-mid font-bold cursor-pointer w-12">
              <div class="flex items-center relative p-2.5 md:p-5 lg:py-3.5 lg:px-7">
                <span>Remove</span>
              </div>
            </th>
          </tr>
        </thead>
        <tbody :class="{ 'animate-pulse': data.isLoading }">
          <template v-if="!data.isLoading">
            <tr
              v-for="(u, index) in data.pollListDataTable"
              :key="index"
              :class="{
                'bg-off-white-1 dark:bg-dark-bgPrimary': index % 2 === 0,
              }"
            >
              <td class="text-xs md:text-sm lg:text-base text-black-1 dark:text-dark-textPrimary font-medium p-2.5 md:p-5 lg:py-3.5 lg:px-7">
                {{ u.name }}
              </td>
              <td class="text-xs md:text-sm lg:text-base text-black-1 dark:text-dark-textPrimary font-medium p-2.5 md:p-5 lg:py-3.5 lg:px-7">
                {{ u.status }}
              </td>
              <td class="text-xs md:text-sm lg:text-base text-magenta font-medium p-2.5 md:p-5 lg:py-3.5 lg:px-7">
                <EyeIcon class="h-5 w-5 md:h-7 md:w-7 cursor-pointer" @click="() => onView(u)" />
              </td>
              <td class="text-xs md:text-sm lg:text-base text-magenta font-medium p-2.5 md:p-5 lg:py-3.5 lg:px-7">
                <EditIcon class="h-5 w-5 md:h-7 md:w-7 cursor-pointer" @click="() => onEdit(u)" />
              </td>
              <td class="text-xs md:text-sm lg:text-base text-magenta font-medium p-2.5 md:p-5 lg:py-3.5 lg:px-7">
                <DownloadIcon class="h-5 w-5 md:h-7 md:w-7 cursor-pointer" @click="() => onDownloadReport(u)" />
              </td>
              <td class="text-xs md:text-sm lg:text-base text-magenta font-medium p-2.5 md:p-5 lg:py-3.5 lg:px-7">
                <DeleteIcon class="h-5 w-5 md:h-7 md:w-7 cursor-pointer" v-if="u.status !== 'ACTIVE'" @click="() => onDelete(u.id)" />
              </td>
            </tr>
          </template>

          <template v-if="data.isLoading">
            <tr
              v-for="n in 10"
              :key="n"
              :class="{
                'bg-grey-light dark:bg-dark-bgPrimary': n % 2 !== 0,
              }"
              class="h-14"
            >
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
          </template>
        </tbody>
      </table>
      <div v-if="data.pollListDataTable.length > 1" class="flex border-t-2 border-grey-light pt-4 lg:pt-7">
        <button
          class="
            w-16
            md:w-20
            h-8
            md:h-10
            text-xs
            md:text-base
            uppercase
            font-bold
            text-grey-mid
            dark:text-dark-textPrimary
            bg-white
            dark:bg-dark-bgSecondary
            border-2 border-grey-light
            dark:border-dark-bgPrimary
            rounded-full
            mr-2
            focus:outline-none
          "
          @click.prevent="paginate(1)"
        >
          First
        </button>

        <div v-for="page in totalPages" :key="`pagination-${page}`">
          <span
            v-if="isDisplayPageNumber(page)"
            :class="page === data.currentPage ? 'border-blue-light' : 'border-grey-light dark:border-dark-bgPrimary'"
            class="flex justify-center items-center w-8 md:w-10 h-8 md:h-10 text-grey-mid dark:text-dark-textPrimary rounded-full border-2 shadow-sm cursor-pointer mr-2"
            @click="paginate(page)"
            >{{ page }}</span
          >
        </div>

        <button
          class="
            w-16
            md:w-20
            h-8
            md:h-10
            text-xs
            md:text-base
            uppercase
            font-bold
            text-grey-mid
            dark:text-dark-textPrimary
            bg-white
            dark:bg-dark-bgSecondary
            border-2 border-grey-light
            dark:border-dark-bgPrimary
            rounded-full
            mr-2
            focus:outline-none
          "
          @click.prevent="paginate(totalPages)"
        >
          Last
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, reactive, onBeforeMount, watch } from "vue";
import { useStore } from "vuex";

import { dynamicPaginate, filterDataCustom } from "@Helpers/datatable";
import { sleep } from "@Helpers/sleep";
// import { deleteUser } from "@API/user/user-repository";
import { useTableData } from "@Libs/hooks/useDataTable";

import DeleteIcon from "@Assets/images/DeleteIcon";
import EyeIcon from "@Assets/images/EyeIcon";
import EditIcon from "@Assets/images/EditIcon";
import DownloadIcon from "@Assets/images/DownloadIcon";
import Popup from "./Popup";

import { doDeletePoll, getPollingReport } from "@API/event/polling-repository";
import { find, debounce, forEach } from "lodash";
import { Field } from "vee-validate";
import { empty } from "@/utils";

// import Create from "./Create";

export default {
  name: "PollingTab",
  props: {
    eventDetail: {
      type: Object,
      required: true,
    },
    isActive: {
      type: Boolean,
      required: true,
    },
  },
  components: {
    DeleteIcon,
    EyeIcon,
    EditIcon,
    Popup,
    Field,
    DownloadIcon,
    // Create,
  },
  emits: ["onUpdate", "toggleLoading"],
  setup(props) {
    const data = reactive({
      curerntFilterText: "",
      currentSort: "fullName",
      currentSortDir: "asc",
      currentPage: 1,
      perPage: 10,
      isLoading: false,
      isNewPoll: false,
      isEditPoll: false,
      pollList: [],
      pollDetail: {},
      searchInfo: "",
      pollListDataTable: [],
    });
    const sortStr = computed({
      get: () => {
        let s = "";
        if (data.currentSortDir === "desc") s += "-";
        return s + data.currentSort;
      },
      set: s => {
        //if s == current sort, reverse
        if (s === data.currentSort) {
          data.currentSortDir = data.currentSortDir === "asc" ? "desc" : "asc";
        } else data.currentSortDir = "asc";
        data.currentSort = s;
      },
    });

    const totalPages = computed(() => Math.ceil(data.pollList.length / data.perPage));

    onBeforeMount(getPolls);
    watch(
      () => data.pollList,
      value => {
        data.searchInfo = "";
        data.pollListDataTable = dynamicPaginate(value, data.currentPage, data.perPage);
      },
    );

    const onSearch = () => {
      let dataRet = dynamicPaginate(data.pollList, data.currentPage, data.perPage);
      if (!empty(data.searchInfo)) {
        dataRet = filterDataCustom(data.searchInfo, data.pollList, ["name"]);
      }
      data.pollListDataTable = dataRet;
    };
    const debounceSearchData = debounce(onSearch, 300);

    async function getPolls() {
      // Show loading
      toggleLoading();
      const { fetchPollingListDatatable } = useTableData();
      data.pollList = await fetchPollingListDatatable(props.eventDetail.id, data.curerntFilterText, sortStr.value);
      // Hide Loading
      toggleLoading();
    }

    function paginate(p) {
      if (p === data.currentPage) return;

      // Set currentPage
      data.currentPage = p;
    }

    function toggleLoading() {
      data.isLoading = !data.isLoading;
    }

    function isDisplayPageNumber(p) {
      if (totalPages.value < 3) return true;
      if (p >= data.currentPage - 1 && p <= data.currentPage + 1) return true;
      return false;
    }

    function doCreatePoll() {
      data.isNewPoll = true;
      data.isEditPoll = true;
      data.pollDetail = {
        name: "",
        status: "DRAFT",
        eventId: props.eventDetail.id,
        time: 0,
        question: [
          {
            name: "",
            isMultiple: false,
            answers: [
              {
                answer: "",
              },
            ],
          },
        ],
      };
    }

    async function doUpdatePoll(isReset = true) {
      if (isReset) {
        data.pollDetail = {};
      }
      await getPolls();
      if (!isReset) {
        data.pollDetail = find(data.pollList, poll => poll.id === data.pollDetail.id);
      }
    }

    return {
      data,
      debounceSearchData,
      totalPages,
      getPolls,
      paginate,
      isDisplayPageNumber,
      doCreatePoll,
      doUpdatePoll,
    };
  },
  watch: {
    isActive(value) {
      if (!value) {
        this.emitter.all.delete("submit-confirm-dialog-polling");
      } else {
        // Listen submit confirm dialog
        this.emitter.on("submit-confirm-dialog-polling", async () => {
          try {
            const { type, payload } = this.$store.state.auth.confirmDialog.confirmAction;
            if (type === "DELETE_POLL") {
              this.$store.dispatch("updateLoadingText", "Removing poll");
              this.$emit("toggleLoading");
              const result = await doDeletePoll(this.eventDetail.id, payload);

              // Show Alert
              this.$store.dispatch("toggleAlert", {
                isAlert: true,
                status: result.success,
                title: result.success ? "Success!" : "Failed!",
                message: result.message,
              });
              await this.getPolls();
            }
          } catch (error) {
            console.log({ error });
          } finally {
            this.$emit("toggleLoading", false);

            this.$store.dispatch("resetConfirmDialog");

            await sleep(5000);

            // Hide Alert
            this.$store.dispatch("toggleAlert", {
              isAlert: false,
            });
          }
        });
      }
    },
  },
  methods: {
    onDelete(pollId) {
      // Show confirm dialog
      this.$store.dispatch("toggleConfirmDialog", {
        eventListen: "submit-confirm-dialog-polling",
        isConfirmDialog: true,
        title: "Delete Poll",
        message: "Are you sure you want to remove this poll?",
        confirmButtonText: "Delete",
        confirmAction: {
          type: "DELETE_POLL",
          payload: pollId,
        },
      });
    },
    async onDownloadReport(poll) {
      try {
        this.$store.dispatch("updateLoadingText", "Removing poll");
        this.$emit("toggleLoading");
        const result = await getPollingReport(this.eventDetail.id, poll.id);
        console.log({ result });
        const dataExport = [];
        forEach(result.data, item => {
          dataExport.push({
            name: item.name,
            question: item.question,
            answer: item.answers.join(", "),
          });
        });
        // Show Alert
        await import("@/helpers/excel").then(excel => {
          excel.exportJsonToExcel({
            data: dataExport,
            filename: `${this.eventDetail.name}_${poll.name}`,
          });
        });
        this.$store.dispatch("toggleAlert", {
          isAlert: true,
          status: result.success,
          title: result.success ? "Success!" : "Failed!",
          message: result.message,
        });
      } catch (error) {
        console.error({ error });
      } finally {
        this.$emit("toggleLoading", false);

        await sleep(5000);

        // Hide Alert
        this.$store.dispatch("toggleAlert", {
          isAlert: false,
        });
      }
    },
    onEdit(poll) {
      this.data.isNewPoll = false;
      this.data.isEditPoll = true;
      this.data.pollDetail = poll;
    },
    onView(poll) {
      this.data.isNewPoll = false;
      this.data.isEditPoll = false;
      this.data.pollDetail = poll;
    },
  },
};
</script>
