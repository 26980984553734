import gql from "graphql-tag";

const ConversationSubscription = gql`
  subscription MySubscription($eventId: String!) {
    onCreateConversation(eventId: $eventId) {
      createdAt
      eventId
      id
      name
      partner
    }
  }
`;

export default ConversationSubscription;
