<template>
  <section class="event-detail relative">
    <h3 v-if="empty(data.eventDetail)" class="text-center text-magenta">
      <template v-if="data.invalidEvent"> Event invalid </template>
    </h3>
    <template v-else>
      <div class="block md:flex justify-between">
        <h1 class="h1-Alternative uppercase text-center mb-3 md:mb-0">
          {{ data.eventDetail.name }}
        </h1>
      </div>

      <div class="flex flex-wrap mt-5 lg:mt-3">
        <div class="w-full">
          <ul class="flex flex-row list-none md:flex-wrap border-b-3 border-grey-light overflow-x-scroll overflow-y-hidden md:overflow-visible">
            <li class="-mb-3px pr-2" v-for="(item, index) in data.dataSource" :key="index">
              <a
                :class="[data.openTabNumber === index ? activeClass : inactiveClass]"
                class="text-base font-bold uppercase block leading-normal cursor-pointer p-1.5 md:px-2 lg:px-6 md:pb-4 pl-0"
                @click="toggleTabs(index)"
              >
                {{ item.title }}
              </a>
            </li>
          </ul>

          <div class="flex flex-col min-w-0 break-words w-full">
            <div class="flex-auto dark:text-dark-textPrimary pt-4">
              <div v-for="(item, index) in data.dataSource" :key="index" :class="[data.openTabNumber !== index ? 'hidden' : 'block']">
                <component
                  :is="item.component"
                  :isActive="data.openTabNumber === index"
                  :eventDetail="data.eventDetail"
                  @onUpdate="onUpdateEvent"
                  @toggleLoading="onToggleLoading($event)"
                  @onChangeTemplate="onChangeTemplate"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </section>
</template>

<script>
import { ref, reactive, computed, onBeforeMount } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";

import GeneralTab from "@Components/pages/event-detail/GeneralTab";
import DesignTab from "@Components/pages/event-detail/DesignTab";
import ContentTab from "@Components/pages/event-detail/ContentTab";
import ReportsTab from "@Components/pages/event-detail/ReportsTab";
import CommentsTab from "@Components/pages/event-detail/CommentsTab";
import SupportTab from "@Components/pages/event-detail/SupportTab";
import UsersTab from "@Components/pages/event-detail/UsersTab";
import Customer from "@Components/pages/event-detail/Customer";
import PollingTab from "@Components/pages/event-detail/PollingTab";
import PrepareTab from "@Components/pages/event-detail/PrepareTab";
import OrderTab from "@Components/pages/event-detail/OrderTab";
import { filter, find, some } from "lodash";
import { empty } from "@/utils";
import { useTableData } from "@Libs/hooks/useDataTable";
import EventSubcription from "@/graphql/subscription/Event";
import { useSubscription } from "@vue/apollo-composable";
import useEmitter from "@Helpers/emitter";

export default {
  name: "EventDetail",
  components: {
    GeneralTab,
    DesignTab,
    ContentTab,
    ReportsTab,
    CommentsTab,
    SupportTab,
    UsersTab,
    PollingTab,
    Customer,
    PrepareTab,
    OrderTab,
  },

  setup() {
    const route = useRoute();
    const store = useStore();
    const mitter = useEmitter();
    const data = reactive({
      openTabNumber: 0,
      dataSource: [
        {
          title: "General",
          component: "GeneralTab",
        },
        {
          title: "Design",
          component: "DesignTab",
        },
        {
          title: "Pre/posts views",
          component: "PrepareTab",
        },
        {
          title: "Links",
          component: "ContentTab",
        },
        {
          title: "Pre-register Viewers",
          component: "Customer",
        },
        {
          title: "Reports",
          component: "ReportsTab",
        },
        store.getters.isAdmin && {
          title: "Users",
          component: "UsersTab",
        },
        {
          title: "Comments",
          component: "CommentsTab",
        },
        {
          title: "Support",
          component: "SupportTab",
        },
        {
          title: "Polling",
          component: "PollingTab",
        },
      ],
      eventDetail: {},
      invalidEvent: false,
      isUpdator: false,
      updatedBy: "",
    });
    data.dataSource = filter(data.dataSource, item => item !== false);

    const activeClass = ref("text-blue-light dark:text-dark-textAccent border-b-3 border-blue-light dark:border-dark-textAccent");
    const inactiveClass = ref("text-grey-mid");
    const doGetEvent = async (isForce = true) => {
      try {
        if (isForce) {
          store.dispatch("toggleLoading");
        }
        data.invalidEvent = false;
        const { fetchEventsListDataTable } = useTableData();
        const eventList = await fetchEventsListDataTable("", "asc");
        let eventDetail = find(eventList, item => item.id === route.params.eventId);
        eventDetail = {
          ...eventDetail,
          donateUrl: eventDetail.donateUrl || {
            name: "",
            url: "",
          },
          buyTicketUrl: eventDetail.buyTicketUrl || {
            name: "",
            url: "",
          },
          bidUrl: eventDetail.bidUrl || {
            name: "",
            url: "",
          },
          leaderBoardUrl: eventDetail.leaderBoardUrl || {
            name: "",
            url: "",
          },
        };
        data.eventDetail = eventDetail;
        if (eventDetail.isPaidEvent && !some(data.dataSource, item => item.title === "Orders")) {
          data.dataSource.push({
            title: "Orders",
            component: "OrderTab",
          });
        }
        data.invalidEvent = empty(data.eventDetail);
      } catch (error) {
        console.log({ error });
        data.invalidEvent = true;
      } finally {
        if (isForce) {
          store.dispatch("closeLoading");
        }
      }
    };
    const { onResult } = useSubscription(EventSubcription, () => ({
      id: route.params.eventId,
    }));
    onResult(({ data: { onEventUpdate: event } }) => {
      if (!data.isUpdator && data.updatedBy !== "PREPARE") {
        store.dispatch("toggleConfirmDialog", {
          eventListen: "trigger_event_updated",
          isConfirmDialog: true,
          title: `Event <b>${event.id}</b> updated`,
          message: `${event.message}`,
          confirmButtonText: "Re-load",
          confirmAction: {
            type: "TRIGGER_EVENT_UPDATED",
          },
        });
      } else {
        data.isUpdator = false;
      }
    });

    mitter.all.delete("trigger_event_updated");
    mitter.on("trigger_event_updated", async () => {
      const { type } = store.state.auth.confirmDialog.confirmAction;
      if (type === "TRIGGER_EVENT_UPDATED") {
        store.dispatch("resetConfirmDialog");
        data.isUpdator = false;
        await doGetEvent();
      }
    });
    onBeforeMount(doGetEvent);

    function toggleTabs(tabNumber) {
      data.openTabNumber = tabNumber;
    }

    function onUpdateEvent(type = "") {
      data.updatedBy = type;
      data.isUpdator = true;
      doGetEvent(false);
    }

    function onToggleLoading(isLoading = true) {
      if (isLoading) {
        store.dispatch("toggleLoading");
      } else {
        store.dispatch("closeLoading");
      }
    }

    function onChangeTemplate(template) {
      const { primaryColour, secondaryColor, font, preEventConfig, postEventConfig, customCSS, settings: settingTemmplate, loginMode, price, isPaidEvent, eventModeMessage, eventMode } = template;
      data.eventDetail = {
        ...data.eventDetail,
        primaryColour,
        secondaryColor,
        preEventConfig,
        postEventConfig,
        customCSS,
        eventMode,
        eventModeMessage,
        settings: {
          ...data.eventDetail,
          ...settingTemmplate,
        },
        font,
        price,
        loginMode,
        isPaidEvent,
      };
    }

    return {
      data,
      activeClass,
      inactiveClass,
      toggleTabs,
      doGetEvent,
      empty,
      onUpdateEvent,
      onToggleLoading,
      onChangeTemplate,
    };
  },
};
</script>
