import gql from "graphql-tag";

const CommentQuery = gql`
  query MyQuery($eventId: String!, $limit: Int!, $nextToken: String) {
    getComments(eventId: $eventId, limit: $limit, nextToken: $nextToken) {
      items {
        content
        createdDate
        email
        eventId
        id
        lastUpdatedDate
        name
        status
      }
      nextToken
    }
  }
`;

export default CommentQuery;
