<template>
  <div class="w-full">
    <div class="flex md:flex-row flex-col justify-between md:items-center mb-2">
      <label class="inline-flex items-center mt-3 cursor-pointer">
        <input type="checkbox" class="form-checkbox h-5 w-5 text-magenta" v-model="isNeedModConfirmComment" />
        <span class="ml-2 text-gray-700 text-base lg:text-xl font-semibold text-black-1 dark:text-dark-textSecondary"> Comment moderation </span>
      </label>
      <div v-if="commentData.data?.length > 0">
        <button @click="doExportCSV" class="button_small-Alternative uppercase bg-magenta border rounded-full p-3 focus:outline-none border-none text-white font-montserrat font-bold">
          Export CSV
        </button>
        <button @click="doClearComment" class="button_small-Alternative ml-2 uppercase bg-magenta border rounded-full p-3 focus:outline-none border-none text-white font-montserrat font-bold">
          Clear
        </button>
      </div>
    </div>

    <div class="flex flex-wrap font-fs-elliot-pro">
      <p v-if="commentData.data?.length === 0" class="w-full text-base lg:text-xl font-semibold text-magenta text-center my-5">No comment</p>

      <div v-else class="flex flex-col min-w-0 break-words w-full h-page-content-full overflow-hidden">
        <div class="flex-auto dark:text-dark-textPrimary pt-5 overflow-y-auto">
          <div>
            <div
              v-for="(comment, index) in orderBy(commentData.data, ['createdDate'], ['desc'])"
              :key="index"
              class="flex justify-between bg-off-white-1 dark:bg-dark-bgPrimary border border-grey-mid rounded p-3 md:p-5 mb-5"
            >
              <div class="flex-1">
                <p class="text-base lg:text-xl font-semibold text-black-1 dark:text-dark-textSecondary" v-html="convertUrlToLink(decodeBase64(comment.content))" />
                <p class="text-base font-semibold text-grey-mid dark:text-grey-dark">
                  Sent by {{ comment.name }} at
                  {{ `${$filters.formatDate(comment.createdDate)} ${$filters.formatTime(comment.createdDate)}` }}
                </p>
              </div>
              <div
                class="lowercase flex items-center text-lg"
                :class="[comment.status === 'DELETED' ? 'text-danger' : comment.status === 'APPROVED' ? 'text-blue-light' : comment.status === 'REJECTED' ? 'text-magenta' : 'text-warning']"
              >
                {{ comment.status }}
              </div>
              <div class="flex items-center justify-end w-1/3" v-if="currentEvent.isNeedModConfirmComment">
                <button
                  class="bg-transparent text-base lg:text-xl text-danger font-bold inline-flex items-center border-none overflow-hidden focus:outline-none py-1.5 px-2 md:py-3 md:px-4 flex-col"
                  @click="doUpdateStatus(comment, 'DELETED')"
                  v-if="comment.status !== 'DELETED'"
                >
                  <DeleteIcon class="h-6 w-6" />
                  <span>Delete</span>
                </button>

                <button
                  class="bg-transparent text-base lg:text-xl text-blue-light font-semibold inline-flex items-center border-none overflow-hidden focus:outline-none py-1.5 px-2 md:py-3 md:px-4 flex-col"
                  @click="doUpdateStatus(comment, 'APPROVED')"
                  v-if="comment.status === 'WAITING' || comment.status === 'DELETED' || comment.status === 'REJECTED'"
                >
                  <ApproveIcon class="h-6 w-6" />
                  <span>Approve</span>
                </button>

                <button
                  class="bg-transparent text-base lg:text-xl text-magenta font-bold inline-flex items-center border-none overflow-hidden focus:outline-none py-1.5 px-2 md:py-3 md:px-4 flex-col"
                  @click="doUpdateStatus(comment, 'REJECTED')"
                  v-if="comment.status === 'WAITING' || comment.status === 'APPROVED'"
                >
                  <RejectIcon class="h-6 w-6" />
                  <span>Reject</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, ref, reactive, watch } from "vue";
import { useRoute } from "vue-router";
import { useQuery, useApolloClient } from "@vue/apollo-composable";
import { sleep } from "@Helpers/sleep";

import { getCommentApproved, getCommentWaiting, doUpdateCommentStatus, doDeleteCommentStatus } from "@API/event/comment-repository";

import ApproveIcon from "@Assets/images/ApproveIcon";
import RejectIcon from "@Assets/images/RejectIcon";
import DeleteIcon from "@Assets/images/DeleteIcon";
import CommentQuery from "@/graphql/query/Comment";
import { CommentCreatedSubcription, CommentUpdatedSubcription } from "@/graphql/subscription/Comment";
import { putEvent } from "@API/event/event-repository";

import { orderBy, map, some, isEmpty, findIndex } from "lodash";
import { formatDate, formatTime } from "@/helpers/date";
import { convertUrlToLink, decodeBase64, encodeBase64 } from "@/utils";

export default {
  name: "CommentsTab",
  props: {
    isActive: {
      type: Boolean,
    },
    eventDetail: {
      type: Object,
      required: true,
    },
  },
  components: {
    ApproveIcon,
    RejectIcon,
    DeleteIcon,
  },

  watch: {
    // isActive(value) {
    //   if (value) {
    //     // await this.doInit();
    //     this.commentData = [];
    //     this.refetch();
    //   }
    // },
    isNeedModConfirmComment: {
      async handler(value) {
        const payload = {
          ...this.currentEvent,
          isNeedModConfirmComment: value,
        };
        const result = await putEvent(payload);
        if (result.success) {
          this.$emit("onUpdate");
        }
        // Show Alert
        this.$store.dispatch("toggleAlert", {
          isAlert: true,
          status: result.success,
          title: result.success ? "Success!" : "Failed!",
          message: result.message,
        });
        this.refetch();

        await sleep(5000);

        // Hide Alert
        this.$store.dispatch("toggleAlert", {
          isAlert: false,
        });
      },
    },
  },
  setup(props) {
    const route = useRoute();

    const currentEvent = computed(() => props.eventDetail);
    const isNeedModConfirmComment = ref(currentEvent.value.isNeedModConfirmComment);

    const { eventId } = route.params;
    let commentData = reactive({
      data: [],
      client: useApolloClient(),
    });
    const variables = reactive({
      eventId,
      limit: 100,
      nextToken: null,
    });
    const { result, subscribeToMore, refetch, fetchMore } = useQuery(CommentQuery, variables);

    watch(result, value => {
      if (value) {
        commentData.data = value.getComments.items;
        if (!isEmpty(value.getComments.nextToken)) {
          doFetchMore();
        }
      }
    });

    const doFetchMore = () => {
      fetchMore({
        variables,
        updateQuery: (
          previousResult,
          {
            fetchMoreResult: {
              getComments: { items, nextToken },
            },
          },
        ) => {
          if (!isEmpty(nextToken)) {
            variables.nextToken = nextToken;
          }
          return {
            ...previousResult,
            getComments: {
              ...previousResult.getComments,
              items: [...previousResult.getComments.items, ...items],
              nextToken: nextToken,
            },
          };
        },
      });
    };

    subscribeToMore(() => ({
      document: CommentUpdatedSubcription,
      variables,
      updateQuery: (
        previousResult,
        {
          subscriptionData: {
            data: { onUpdateComment },
          },
        },
      ) => {
        const indexComment = findIndex(previousResult.getComments.items, item => item.id === onUpdateComment.id);
        // console.log(indexComment, { onUpdateComment });
        if (indexComment > -1) {
          previousResult.getComments.items.splice(indexComment, 1, onUpdateComment);
        } else {
          previousResult.getComments.items.push(onUpdateComment);
        }
        return previousResult;
      },
    }));

    subscribeToMore(() => ({
      document: CommentCreatedSubcription,
      variables,
      updateQuery: (
        previousResult,
        {
          subscriptionData: {
            data: { onCreateComment },
          },
        },
      ) => {
        const indexComment = findIndex(previousResult.getComments.items, item => item.id === onCreateComment.id);
        // console.log(indexComment, { onCreateComment });
        if (indexComment > -1) {
          previousResult.getComments.items.splice(indexComment, 1, onCreateComment);
        } else {
          previousResult.getComments.items.push(onCreateComment);
        }
        return previousResult;
      },
    }));

    function doUpdateCommentStatusCached(comment, status) {
      if (status === "DELETED") {
        const cachedData = commentData.client.client.readQuery({
          query: CommentQuery,
          variables,
        });
        const messIndex = findIndex(cachedData.getComments.items, item => item.id === comment.id);
        if (messIndex >= 0) {
          cachedData.getComments.items.splice(messIndex, 1, {
            ...comment,
            status,
          });

          commentData.client.client.writeQuery({
            query: CommentQuery,
            variables,
            data: cachedData,
          });
        }
      }
    }
    return {
      eventId,
      commentData,
      refetch,
      currentEvent,
      isNeedModConfirmComment,
      convertUrlToLink,
      decodeBase64,
      doUpdateCommentStatusCached,
    };
  },
  methods: {
    orderBy,
    async doInit() {
      try {
        const dataApproved = await getCommentApproved(this.eventId);
        const dataWaiting = await getCommentWaiting(this.eventId);
        this.commentData.data = [...dataWaiting.data, ...dataApproved.data];
      } catch (error) {
        console.log({ error });
      }
    },
    async doUpdateStatus(comment, status) {
      try {
        this.$store.dispatch("updateLoadingText", "Updating comment's status");
        this.$emit("toggleLoading");
        await doUpdateCommentStatus(this.eventId, {
          status,
          commentId: comment.id,
        });
        this.doUpdateCommentStatusCached(comment, status);
        // await this.doInit();
        // this.refetch();
        // }
      } catch (error) {
        console.log({ error });
      } finally {
        this.$emit("toggleLoading", false);
      }
    },
    doExportCSV() {
      import("@/helpers/excel").then(excel => {
        excel.exportJsonToExcel({
          priority: ["name", "email", "content"],
          data: map(orderBy(this.commentData, ["lastUpdatedDate"], ["desc"]), item => ({
            name: item.name,
            email: item.email,
            content: decodeBase64(item.content),
            status: item.status,
            createdDate: `${formatDate(item.createdDate)} ${formatTime(item.createdDate)}`,
            lastUpdatedDate: `${formatDate(item.lastUpdatedDate)} ${formatTime(item.lastUpdatedDate)}`,
          })),
          filename: this.eventId,
        });
      });
    },
    async doClearComment() {
      this.$store.dispatch("updateLoadingText", "Clearing all comments");
      this.$emit("toggleLoading");
      await doDeleteCommentStatus(this.eventId);
      this.refetch();
      this.$emit("toggleLoading", false);
    },
  },
};
</script>
