<template>
  <div>
    <p v-if="ordersListDataTable?.length === 0" class="w-full text-base lg:text-xl font-semibold text-magenta text-center my-5 font-fs-elliot-pro">No order</p>
    <table class="text-left w-full" v-else>
      <thead>
        <tr>
          <th class="text-xs md:text-sm text-grey-mid font-bold cursor-pointer">
            <div class="flex items-center relative p-2.5 md:p-5 lg:py-3.5 lg:px-7">
              <span>ID</span>
            </div>
          </th>
          <th class="text-xs md:text-sm text-grey-mid font-bold cursor-pointer">
            <div class="flex items-center relative p-2.5 md:p-5 lg:py-3.5 lg:px-7">
              <span>Last updated</span>
            </div>
          </th>
          <th class="text-xs md:text-sm text-grey-mid font-bold cursor-pointer">
            <div class="flex items-center relative p-2.5 md:p-5 lg:py-3.5 lg:px-7">
              <span>Status</span>
            </div>
          </th>
        </tr>
      </thead>
      <tbody :class="{ 'animate-pulse': data.isLoading }">
        <template v-if="!data.isLoading">
          <tr
            v-for="(u, index) in ordersListDataTable"
            :key="index"
            :class="{
              'bg-off-white-1 dark:bg-dark-bgPrimary': index % 2 === 0,
            }"
          >
            <td class="text-xs md:text-sm lg:text-base text-black-1 dark:text-dark-textPrimary font-medium p-2.5 md:p-5 lg:py-3.5 lg:px-7">
              {{ u.id }}
            </td>
            <td class="text-xs md:text-sm lg:text-base text-black-1 dark:text-dark-textPrimary font-medium p-2.5 md:p-5 lg:py-3.5 lg:px-7">
              {{ formatDateTime(u.lastUpdatedDate) }}
            </td>
            <td class="text-xs md:text-sm lg:text-base text-black-1 dark:text-dark-textPrimary font-medium p-2.5 md:p-5 lg:py-3.5 lg:px-7">
              {{ u.status }}
            </td>
          </tr>
        </template>

        <template v-if="data.isLoading">
          <tr
            v-for="n in 10"
            :key="n"
            :class="{
              'bg-grey-light dark:bg-dark-bgPrimary': n % 2 !== 0,
            }"
            class="h-14"
          >
            <td></td>
            <td></td>
            <td></td>
          </tr>
        </template>
      </tbody>
    </table>
    <div v-if="ordersListDataTable.length" class="flex border-t-2 border-grey-light pt-4 lg:pt-7">
      <button
        class="
          w-16
          md:w-20
          h-8
          md:h-10
          text-xs
          md:text-base
          uppercase
          font-bold
          text-grey-mid
          dark:text-dark-textPrimary
          bg-white
          dark:bg-dark-bgSecondary
          border-2 border-grey-light
          dark:border-dark-bgPrimary
          rounded-full
          mr-2
          focus:outline-none
        "
        @click.prevent="paginate(1)"
      >
        First
      </button>

      <div v-for="page in totalPages" :key="`pagination-${page}`">
        <span
          v-if="isDisplayPageNumber(page)"
          :class="page === data.currentPage ? 'border-blue-light' : 'border-grey-light dark:border-dark-bgPrimary'"
          class="flex justify-center items-center w-8 md:w-10 h-8 md:h-10 text-grey-mid dark:text-dark-textPrimary rounded-full border-2 shadow-sm cursor-pointer mr-2"
          @click="paginate(page)"
          >{{ page }}</span
        >
      </div>

      <button
        class="
          w-16
          md:w-20
          h-8
          md:h-10
          text-xs
          md:text-base
          uppercase
          font-bold
          text-grey-mid
          dark:text-dark-textPrimary
          bg-white
          dark:bg-dark-bgSecondary
          border-2 border-grey-light
          dark:border-dark-bgPrimary
          rounded-full
          mr-2
          focus:outline-none
        "
        @click.prevent="paginate(totalPages)"
      >
        Last
      </button>
    </div>
  </div>
</template>

<script>
import { computed, reactive, onBeforeMount } from "vue";
import { useStore } from "vuex";

import { dynamicPaginate } from "@Helpers/datatable";
import { sleep } from "@Helpers/sleep";
// import { deleteUser } from "@API/user/user-repository";
import { useTableData } from "@Libs/hooks/useDataTable";

import DeleteIcon from "@Assets/images/DeleteIcon";
import { filter, find, map } from "lodash";
import { formatDateTime } from "@Helpers/date";

import { empty } from "@/utils";

export default {
  name: "UsersTab",
  props: {
    eventDetail: {
      type: Object,
      required: true,
    },
    isActive: {
      type: Boolean,
      required: true,
    },
  },
  // components: {
  //   DeleteIcon,
  // },
  emits: ["onUpdate", "toggleLoading"],
  setup(props) {
    const store = useStore();
    const data = reactive({
      curerntFilterText: "",
      currentSort: "fullName",
      currentSortDir: "asc",
      currentPage: 1,
      perPage: 10,
      isLoading: false,
      orderList: [],
    });
    const sortStr = computed({
      get: () => {
        let s = "";
        if (data.currentSortDir === "desc") s += "-";
        return s + data.currentSort;
      },
      set: s => {
        //if s == current sort, reverse
        if (s === data.currentSort) {
          data.currentSortDir = data.currentSortDir === "asc" ? "desc" : "asc";
        } else data.currentSortDir = "asc";
        data.currentSort = s;
      },
    });
    const ordersListDataTable = computed(() => dynamicPaginate(data.orderList, data.currentPage, data.perPage));
    const totalPages = computed(() => Math.ceil(data.orderList.length / data.perPage));

    onBeforeMount(getUsers);

    async function getUsers() {
      // Show loading
      toggleLoading();
      const { fetchOrderListDatatable } = useTableData();

      data.orderList = await fetchOrderListDatatable(props.eventDetail.id, data.curerntFilterText, sortStr.value);

      // Hide Loading
      toggleLoading();
    }

    function paginate(p) {
      if (p === data.currentPage) return;

      // Set currentPage
      data.currentPage = p;
    }

    function toggleLoading() {
      data.isLoading = !data.isLoading;
    }

    function isDisplayPageNumber(p) {
      if (totalPages.value < 3) return true;
      if (p >= data.currentPage - 1 && p <= data.currentPage + 1) return true;
      return false;
    }

    return {
      data,
      ordersListDataTable,
      totalPages,
      getUsers,
      paginate,
      isDisplayPageNumber,
      formatDateTime,
    };
  },
};
</script>
