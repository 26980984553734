import gql from "graphql-tag";

const ConversationQuery = gql`
  query MyQuery($eventId: String!) {
    getConversations(eventId: $eventId) {
      items {
        partner
        name
        id
        eventId
        createdAt
      }
      nextToken
    }
  }
`;

export default ConversationQuery;
