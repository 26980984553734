<template>
  <div class="p-4">
    <div v-if="!isEmpty(pollSelected) && isEdit" class="flex flex-col-reverse md:flex-row items-center justify-between py-2">
      <span class="text-danger text-left w-full md:w-7/12">
        {{ isInvalidInput ? "Please input data" : "" }}
      </span>
      <div class="flex items-center justify-end w-full md:w-5/12">
        <div class="flex flex-col items-center cursor-pointer text-success" @click="onAddQuestion">
          <AddIcon class="h-5 w-5 md:h-7 md:w-7" />
          Add Question
        </div>
        <div class="flex flex-col items-center cursor-pointer text-magenta ml-2" @click="onSubmitPolling">
          <ApproveIcon class="h-5 w-5 md:h-7 md:w-7" />
          {{ isNewPoll ? "Create poll" : "Update Poll" }}
        </div>
      </div>
    </div>

    <div v-if="isEdit" class="flex flex-col font-medium max-h-screen-2/3 overflow-y-auto text-left p-1">
      <!-- <div>
        <p>Starting time</p>
        <Datepicker
          v-model="timePicker2"
          type="datetime"
          format="MM/dd/yyyy HH:mm"
          class="
            w-full
            appearance-none
            bg-off-white-1
            dark:bg-dark-bgPrimary
            text-xs
            md:text-lg
            text-black-1
            dark:text-dark-textPrimary
            border
            focus:border-none focus:outline-none focus:ring-1 focus:ring-magenta
            rounded
            py-2
            md:py-3
          "
          :dark="theme === 'dark'"
        />
      </div>
      <div class="my-2">
        <p>Duration (seconds)</p>
        <input
          v-model.number="pollSelected.time"
          class="
            w-full
            appearance-none
            bg-off-white-1
            dark:bg-dark-bgPrimary
            text-xs
            md:text-lg
            text-black-1
            dark:text-dark-textPrimary
            border
            focus:border-none focus:outline-none focus:ring-1 focus:ring-magenta
            rounded
            py-2
            md:py-3
            px-2
            md:px-4
          "
          placeholder="Enter polling duration"
          :class="[
            pollSelected.time <= 0 ? 'border-danger' : 'border-grey-mid',
          ]"
          @keypress="isNumberInput($event)"
          :readonly="!isEdit"
        />
      </div> -->
      <div class="my-2 w-full flex flex-col md:flex-row items-start justify-between">
        <div class="w-full md:w-6/12">
          <p>Status</p>
          <ComboBox v-model="pollSelected.status" :options="['DRAFT', 'ACTIVE', 'DISABLED']" />
        </div>
        <div class="w-full md:w-5/12 mt-2 md:mt-0">
          <p>Show Result</p>
          <Switch v-model="pollSelected.isPublicResult" class="md:mt-2" />
        </div>
      </div>
      <div class="my-2">
        <p>Polling Button Text</p>
        <input
          v-model="pollSelected.btnSubmitText"
          class="
            w-full
            appearance-none
            bg-off-white-1
            dark:bg-dark-bgPrimary
            text-xs
            md:text-lg
            text-black-1
            dark:text-dark-textPrimary
            border
            focus:border-none focus:outline-none focus:ring-1 focus:ring-magenta
            rounded
            py-2
            md:py-3
            px-2
            md:px-4
          "
          placeholder="Enter poll button text"
          :class="[isEmpty(pollSelected.btnSubmitText) ? 'border-danger' : 'border-grey-mid']"
          :readonly="!isEdit"
        />
      </div>
      <div>
        <p>Polling name</p>
        <input
          v-model="pollSelected.name"
          class="
            w-full
            appearance-none
            bg-off-white-1
            dark:bg-dark-bgPrimary
            text-xs
            md:text-lg
            text-black-1
            dark:text-dark-textPrimary
            border
            focus:border-none focus:outline-none focus:ring-1 focus:ring-magenta
            rounded
            py-2
            md:py-3
            px-2
            md:px-4
          "
          placeholder="Enter poll name"
          :class="[isEmpty(pollSelected.name) ? 'border-danger' : 'border-grey-mid']"
          :readonly="!isEdit"
        />
      </div>
      <div v-if="!isEmpty(pollSelected)" class="mt-1">
        <Question
          v-for="(question, index) in pollSelected.question"
          :key="index"
          :question="question"
          :number="index"
          :isEdit="isEdit"
          v-model="question.name"
          @onUpdate="onAnswerChanged"
          :ref="`question-${index}`"
        />
      </div>
    </div>
  </div>
</template>
<script>
import Question from "./Question";
import AddIcon from "@Assets/images/AddIcon";
import ApproveIcon from "@Assets/images/ApproveIcon";
import { isEmpty, set, some, isNumber } from "lodash";
import { doCreatePoll, doUpdatePoll } from "@API/event/polling-repository";
import { formatDateTime, getMomentT } from "@Helpers/date";
import ComboBox from "@/components/layouts/ComboBox";
import Switch from "@/components/layouts/Switch.vue";
export default {
  props: {
    pollDetail: {
      type: Object,
      required: true,
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
    isNewPoll: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    Question,
    AddIcon,
    ApproveIcon,
    ComboBox,
    // Datepicker,
    Switch,
  },

  computed: {
    isInvalidInput() {
      return (
        isEmpty(this.pollSelected.name) || some(this.pollSelected.question, item => isEmpty(item.name) || some(item.answers, answer => isEmpty(answer.answer)))
        // ||
        // this.pollSelected.time <= 0 ||
        // isEmpty(this.timePicker)
      );
    },
    theme() {
      return this.$store.getters.getTheme;
    },
  },

  data: () => ({
    pollSelected: {},
    timePicker: "",
    timePicker2: null,
  }),
  watch: {
    pollDetail: {
      handler(value) {
        this.pollSelected = value;
        this.timePicker2 = value.startTime;
        this.timePicker = !isEmpty(value.startTime) ? formatDateTime(value.startTime) : null;
      },
      immediate: true,
    },
    timePicker2(value) {
      this.timePicker = formatDateTime(value);
    },
  },
  methods: {
    isEmpty,
    isNumber,
    isNumberInput(evt) {
      evt = evt || window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode !== 45 && charCode > 31 && (charCode < 48 || charCode > 57)) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    onAnswerChanged([question, index, attribute, value]) {
      if (attribute === "DELETE_QUESTION") {
        return delete this.pollSelected.question.splice(index, 1);
      }
      if (Object.prototype.hasOwnProperty.call(question, attribute)) {
        question[attribute] = value;
      } else {
        switch (attribute) {
          case "DELETE_ANSWER":
            if (question.answers.length > 1) {
              question.answers.splice(value, 1);
            } else {
              question.answers[value].answer = "";
            }
            break;
          case "RESET_ANSWER":
            question = value;
            break;
          case "ADD_ANSWER":
            question.answers.push({
              answer: "",
            });
            break;
        }
      }

      set(this.pollSelected.question, index, question);
    },
    onAddQuestion() {
      if (!this.isInvalidInput) {
        this.pollSelected = {
          ...this.pollSelected,
          question: [
            ...this.pollSelected.question,
            {
              isMultiple: false,
              name: "",
              answers: [
                {
                  answer: "",
                },
              ],
            },
          ],
        };
      }
    },
    async onSubmitPolling() {
      if (!this.isInvalidInput) {
        try {
          this.$store.dispatch("updateLoadingText", "Creating new poll");
          this.$store.dispatch("toggleLoading");
          if (this.isNewPoll) {
            await doCreatePoll(this.pollDetail.eventId, {
              ...this.pollSelected,
              startTime: getMomentT(new Date()).toISOString(),
            });
          } else {
            await doUpdatePoll(this.pollDetail.eventId, this.pollDetail.id, this.pollSelected);
          }
          this.$emit("onUpdate");
        } catch (error) {
          console.log({ error });
        } finally {
          this.$store.dispatch("closeLoading");
        }
      }
    },
  },
};
</script>
<style >
.dp__input_icon {
  left: initial;
  right: 20px;
}
.dp__input_icon_pad {
  padding-left: 10px;
}
.dp__input {
  background-color: transparent;
  border: none;
}
</style>
