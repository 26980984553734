<template>
  <form class="font-fs-elliot-pro py-5">
    <div class="flex flex-col md:flex-row">
      <div class="w-full md:w-1/3">
        <label for="number-visitors" class="block capitalize tracking-wide text-grey-mid md:text-lg font-montserrat font-semibold pt-2.5"> Event viewership data </label>
      </div>
      <div class="flex-1">
        <div class="flex flex-col mb-2">
          <span class="text-lg"> Time zone </span>
          <ComboBox v-model="data.timeZone" :options="data.timeZoneList" />
        </div>
        <div class="flex flex-col mb-2">
          <span class="text-lg"> Time format </span>
          <ComboBox
            v-model="data.dateFormat"
            :options="[
              {
                label: '2021-11-23 / 23:12:45',
                value: 'YYYY-MM-DD / HH:mm:ss',
              },
              {
                label: '23:12:45 / 2021-11-23',
                value: 'HH:mm:ss / YYYY-MM-DD',
              },
              {
                label: '11/23/2021 11:12:45 PM',
                value: 'MM/DD/YYYY hh:mm:ss A',
              },
            ]"
          />
        </div>
        <button class="bg-transparent md:text-lg text-magenta font-bold inline-flex items-center border-none overflow-hidden focus:outline-none py-3" @click.prevent="doExportDataTracked">
          <DownloadIcon class="w-4 md:w-6 h-4 md:h-6 mr-1 lg:mr-2" />
          <span>Download Excel</span>
        </button>
      </div>
    </div>

    <div class="flex">
      <div class="w-full md:w-1/3">
        <label for="number-visitors" class="block capitalize tracking-wide text-grey-mid md:text-lg font-montserrat font-semibold pt-2.5">
          Current live connections
          <span class="text-magenta">[{{ data.userOnline }}]</span>
        </label>
      </div>
      <div class="flex-1">
        <button class="bg-transparent md:text-lg text-magenta font-bold inline-flex items-center border-none overflow-hidden focus:outline-none py-3" @click.prevent="doGetUserOnline">
          <ReloadIcon class="w-4 md:w-6 h-4 md:h-6 mr-1 lg:mr-2" />
          <span>Refresh</span>
        </button>
      </div>
    </div>
    <UserConnected ref="userConnected" :eventDetail="eventDetail" @userOnline="data.userOnline = $event" />
  </form>
</template>

<script>
import DownloadIcon from "@Assets/images/DownloadIcon";
import ReloadIcon from "@Assets/images/ReloadIcon";
import { getMonitorInfo } from "@API/event/event-repository";
import UserConnected from "./UserConnected.vue";

import { useRoute } from "vue-router";
import { useStore } from "vuex";
import { sleep } from "@Helpers/sleep";
import { empty, convertMsToTime } from "@/utils";
import { orderBy, map, groupBy, omit, sumBy, last, head, get } from "lodash";
import { getMomentTimezoneList, formatDateTimeWithTz } from "@/helpers/date";
import { ref, reactive } from "vue";
import ComboBox from "@/components/layouts/ComboBox";
export default {
  name: "ReportsTab",
  props: {
    eventDetail: {
      type: Object,
      required: true,
    },
  },
  emits: ["toggleLoading"],
  components: {
    DownloadIcon,
    ReloadIcon,
    UserConnected,
    ComboBox,
  },
  setup(props, { emit }) {
    const route = useRoute();
    const store = useStore();
    const { eventId } = route.params;
    const userConnected = ref(null);
    const data = reactive({
      userOnline: 0,
      timeZone: "UTC",
      dateFormat: "HH:mm:ss / YYYY-MM-DD",
      timeZoneList: getMomentTimezoneList(),
    });

    const doExportDataTracked = async () => {
      try {
        store.dispatch("updateLoadingText", "Exporting data tracking");
        emit("toggleLoading");
        const resp = await getMonitorInfo(eventId);
        const resultStatus = !empty(resp.data);
        if (resultStatus) {
          const dataExported = map(groupBy(resp.data, "viewerEmail"), item => {
            const userMonitors = orderBy(item, ["lastUpdatedDate"]);
            const lastItem = last(userMonitors);
            const firstItem = head(userMonitors);
            const userMonitor = omit(userMonitors[0], ["id", "eventId"]);
            const numberOf = {
              chatHelp: sumBy(userMonitors, "numberOf.chatHelp"),
              chatMessage: sumBy(userMonitors, "numberOf.chatMessage"),
            };
            if (!empty(get(props.eventDetail, "bidUrl.name"))) {
              numberOf.bidButtonClicked = sumBy(userMonitors, "numberOf.button1Clicked");
            }
            if (!empty(get(props.eventDetail, "donateUrl.name"))) {
              numberOf.donateButtonClicked = sumBy(userMonitors, "numberOf.button2Clicked");
            }
            if (!empty(get(props.eventDetail, "buyTicketUrl.name"))) {
              numberOf.buyButtonClicked = sumBy(userMonitors, "numberOf.button3Clicked");
            }
            // console.log(`${formatDate(firstItem.createdDate)} ${formatTime(firstItem.createdDate, true)}`);
            // console.log(`${formatDateTimeWithTz(firstItem.createdDate, data.timeZone, data.dateFormat)}`);
            return {
              ...userMonitor,
              deviceType: map(userMonitors, device => device.deviceType).join(" | "),
              totalVisitedTime: convertMsToTime(sumBy(userMonitors, "totalVisitedTime")),
              lastUpdatedDate: `${formatDateTimeWithTz(lastItem.createdDate, data.timeZone, data.dateFormat)}`,
              createdDate: `${formatDateTimeWithTz(firstItem.createdDate, data.timeZone, data.dateFormat)}`,
              visitedAt: map(userMonitors, visisted => `${formatDateTimeWithTz(visisted.visitedAt, data.timeZone, data.dateFormat)}`).join(" | "),
              numberOf,
            };
          });
          import("@/helpers/excel").then(excel => {
            excel.exportJsonToExcel({
              priority: ["viewerName", "totalVisitedTime", "deviceType"],
              data: dataExported,
              filename: `${eventId}_${data.timeZone}_${data.dateFormat}`,
            });
          });
        }
        // Show Alert
        store.dispatch("toggleAlert", {
          isAlert: true,
          status: resultStatus,
          title: resultStatus ? "Success!" : "Failed!",
          message: resultStatus ? "Data exported" : "Data empty",
        });
      } catch (error) {
        console.log({ error });
      } finally {
        emit("toggleLoading", false);

        await sleep(5000);

        // Hide Alert
        store.dispatch("toggleAlert", {
          isAlert: false,
        });
      }
    };

    const doGetUserOnline = () => {
      userConnected.value.getUsers();
    };
    return {
      doExportDataTracked,
      doGetUserOnline,
      userConnected,
      data,
    };
  },
};
</script>
