import gql from "graphql-tag";

const MessagesMuation = gql`
  mutation MyMutation(
    $conversationId: String!
    $body: String!
    $sender: String!
    $userName: String!
  ) {
    createMessage(
      body: $body
      conversationId: $conversationId
      sender: $sender
      userName: $userName
    ) {
      body
      conversationId
      createdAt
      id
      sender
      userName
    }
  }
`;

export default MessagesMuation;
