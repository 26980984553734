<template>
  <Form v-slot="{ errors }" :validation-schema="schema" class="flex flex-col font-fs-elliot-pro py-5 mb-8 md:mb-0" @submit="onSubmit">
    <div class="flex items-center flex-col md:flex-row md:mb-7 lg:mb-8">
      <div class="w-1/2 md:w-1/3 lg:w-1/4">
        <label class="block capitalize tracking-wide text-grey-mid text-xs md:text-lg font-montserrat font-semibold pt-2.5"> Event Template: </label>
      </div>
      <div class="w-full md:w-2/3 mt-2 md:mt-0">
        <ComboBox
          class="w-full"
          v-model="data.eventTemplateId"
          :options="
            data.eventTemplateList.map(item => ({
              label: item.name,
              value: item.id,
            }))
          "
        />
      </div>
    </div>
    <div class="flex flex-col md:flex-row md:mb-7 lg:mb-8">
      <div class="w-1/2 md:w-1/3 lg:w-1/4">
        <label class="block capitalize tracking-wide text-grey-mid text-xs md:text-base font-montserrat font-semibold pt-2.5"> Name: </label>
      </div>

      <div class="w-full md:w-2/3 mt-2 md:mt-0">
        <Field
          v-model="currentEvent.name"
          name="name"
          type="text"
          :class="{
            'text-danger border border-danger': errors['name'],
          }"
          class="block w-full appearance-none bg-off-white-1 dark:bg-dark-bgPrimary text-xs md:text-base focus:ring-magenta focus:border-magenta rounded py-2 md:py-3 px-2 md:px-4 mb-3"
        />
        <p class="text-danger dark:text-danger text-base italic">
          {{ errors["name"] }}
        </p>
      </div>
    </div>

    <div class="flex flex-col md:flex-row md:mb-7 lg:mb-8">
      <div class="w-1/2 md:w-1/3 lg:w-1/4">
        <label class="block capitalize tracking-wide text-grey-mid text-xs md:text-base font-montserrat font-semibold pt-2.5"> Page Title: </label>
      </div>

      <div class="w-full md:w-2/3 mt-2 md:mt-0">
        <Field
          v-model="currentEvent.title"
          name="title"
          type="text"
          :class="{
            'text-danger border border-danger': errors['title'],
          }"
          class="block w-full appearance-none bg-off-white-1 dark:bg-dark-bgPrimary text-xs md:text-base focus:ring-magenta focus:border-magenta rounded py-2 md:py-3 px-2 md:px-4 mb-3"
        />
        <p class="text-danger dark:text-danger text-base italic">
          {{ errors["title"] }}
        </p>
      </div>
    </div>

    <div class="flex flex-col md:flex-row md:mb-7 lg:mb-8">
      <div class="w-1/2 md:w-1/3 lg:w-1/4">
        <label class="block capitalize tracking-wide text-grey-mid text-xs md:text-base font-montserrat font-semibold pt-2.5"> Date: </label>
      </div>

      <div class="w-full md:w-2/3 mt-2 md:mt-0">
        <div class="inline-flex w-full">
          <div class="w-20 md:w-1/2 lg:w-1/3">
            <Field
              v-model="data.date"
              name="eventDate"
              type="date"
              :class="{
                'text-danger border border-danger': errors['eventDate'],
              }"
              class="
                appearance-none
                bg-off-white-1
                dark:bg-dark-bgPrimary
                text-xs
                md:text-base
                text-black-1
                dark:text-dark-textPrimary
                text-center
                md:text-left
                border border-grey-mid
                focus:ring-magenta focus:border-magenta
                rounded
                py-2
                md:py-3
                px-2
                md:px-4
                mb-3
                mr-4
                md:mr-12
              "
            />
            <p class="text-danger dark:text-danger text-base italic mr-1 md:mr-14 lg:mr-28">
              {{ errors["eventDate"] }}
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="flex flex-col md:flex-row md:mb-7 lg:mb-8">
      <div class="w-1/2 md:w-1/3 lg:w-1/4">
        <label class="block capitalize tracking-wide text-grey-mid text-xs md:text-base font-montserrat font-semibold pt-2.5"> Welcome Message: </label>
      </div>

      <div class="w-full md:w-2/3 mt-2 md:mt-0">
        <Field
          v-model="currentEvent.welcomeMessage"
          as="textarea"
          name="welcomeMessage"
          rows="4"
          :class="{
            'text-danger border border-danger': errors['welcomeMessage'],
          }"
          class="
            w-full
            text-xs
            md:text-base
            text-black-1
            dark:text-dark-textPrimary
            bg-off-white-1
            dark:bg-dark-bgPrimary
            border border-grey-mid
            focus:ring-magenta focus:border-magenta
            rounded
            p-2
            md:p-3
          "
        />
        <p class="text-danger dark:text-danger text-base italic">
          {{ errors["welcomeMessage"] }}
        </p>
      </div>
    </div>

    <div class="flex flex-col md:flex-row md:mb-7 lg:mb-8">
      <div class="w-1/2 md:w-1/3 lg:w-1/4">
        <label class="block capitalize tracking-wide text-grey-mid text-xs md:text-base font-montserrat font-semibold"> Virtual Event URL: </label>
      </div>

      <div class="w-full md:w-2/3 mt-2 md:mt-0">
        <a :href="`${eventUrl}/${currentEvent.id}`" target="_blank">
          {{ `${eventUrl}/${currentEvent.id}` }}
        </a>
      </div>
    </div>

    <div class="flex flex-col md:flex-row md:mb-7 lg:mb-5">
      <div class="w-1/2 md:w-1/3 lg:w-1/4">
        <label class="block capitalize tracking-wide text-grey-mid text-xs md:text-lg font-montserrat font-semibold pt-2.5"> Login Mode: </label>
      </div>
      <div class="md:flex md:items-center w-full md:w-2/3 mt-2 md:mt-0">
        <ComboBox
          class="w-full"
          v-model="data.loginMode"
          :options="[
            { label: 'Public', value: 'PUBLIC' },
            { label: 'Pre-registered Viewer Logins', value: 'CUSTOM_LIST' },
          ]"
        />
      </div>
    </div>

    <div class="flex my-2 md:mb-7 lg:mb-8">
      <div class="w-1/2 md:w-1/3 lg:w-1/4">
        <label class="block capitalize tracking-wide text-grey-mid text-xs md:text-base font-montserrat font-semibold pt-2.5"> Password Protection: </label>
      </div>
      <div class="w-2/3 flex flex-col">
        <Switch v-model="settings.isProtect" class="mb-2 self-start" />
        <template v-if="settings.isProtect">
          <Field
            v-model="settings.password"
            type="text"
            name="password"
            rows="5"
            :class="{
              'text-danger border border-danger': errors['password'],
            }"
            class="
              w-full
              text-xs
              md:text-base
              text-black-1
              dark:text-dark-textPrimary
              bg-off-white-1
              dark:bg-dark-bgPrimary
              border border-grey-mid
              focus:ring-magenta focus:border-magenta
              rounded
              p-2
              md:p-3
            "
          />
          <p class="text-danger dark:text-danger text-base italic">
            {{ errors["password"] }}
          </p>
        </template>
      </div>
    </div>
    <div class="flex my-2 md:mb-7 lg:mb-8">
      <div class="w-1/2 md:w-1/3 lg:w-1/4">
        <label class="block capitalize tracking-wide text-grey-mid text-xs md:text-base font-montserrat font-semibold pt-2.5"> Paid event: </label>
      </div>
      <div class="w-2/3 flex flex-col">
        <Switch v-model="data.isPaidEvent" class="mb-2 self-start" />
        <template v-if="data.isPaidEvent">
          <Field
            v-model="data.price"
            type="text"
            name="price"
            rows="5"
            :class="{
              'text-danger border border-danger': errors['price'],
            }"
            class="
              w-full
              text-xs
              md:text-base
              text-black-1
              dark:text-dark-textPrimary
              bg-off-white-1
              dark:bg-dark-bgPrimary
              border border-grey-mid
              focus:ring-magenta focus:border-magenta
              rounded
              p-2
              md:p-3
            "
          />
          <p class="text-danger dark:text-danger text-base italic">
            {{ errors["price"] }}
          </p>
        </template>
      </div>
    </div>
    <div class="flex my-2 md:mb-7 lg:mb-8">
      <div class="w-1/2 md:w-1/3 lg:w-1/4">
        <label class="block capitalize tracking-wide text-grey-mid text-xs md:text-base font-montserrat font-semibold pt-2.5"> Allow anonymous login: </label>
      </div>
      <div class="w-2/3 flex flex-col">
        <Switch v-model="settings.isAnonymousLogin" class="mb-2 self-start" />
      </div>
    </div>
    <button
      type="submit"
      class="button_large-Alternative absolute right-0 bottom-0 md:bottom-auto md:-top-8 md:-right-8 uppercase bg-magenta border rounded-full py-3 lg:py-4 lg:px-8 focus:outline-none"
    >
      Save
    </button>
  </Form>
</template>

<script>
import { computed, reactive, onBeforeMount, watch } from "vue";

import { getMomentT, formatDate } from "@Helpers/date";
import { Form, Field } from "vee-validate";
import * as Yup from "yup";
import * as _ from "lodash";

import { sleep } from "@Helpers/sleep";
import { putEvent } from "@API/event/event-repository";
import Switch from "@/components/layouts/Switch.vue";
import ComboBox from "@/components/layouts/ComboBox";
import { getEventTemplates } from "@API/event/template-repository";
import { get } from "lodash";

export default {
  name: "GeneralTab",
  props: {
    eventDetail: {
      type: Object,
      required: true,
    },
  },
  components: {
    Form,
    Field,
    Switch,
    ComboBox,
  },
  emits: ["toggleLoading", "onUpdate", "onChangeTemplate"],
  setup(props, { emit }) {
    const currentEvent = reactive(props.eventDetail);
    const settings = reactive({
      isProtect: !!get(currentEvent, "settings.isProtect"),
      password: get(currentEvent, "settings.password", ""),
      isAnonymousLogin: get(currentEvent, "settings.isAnonymousLogin", false),
    });
    const data = reactive({
      date: formatDate(currentEvent.dateTime, "yyyy-MM-DD"),
      loginMode: currentEvent.loginMode ?? "PUBLIC",
      isPaidEvent: currentEvent.isPaidEvent ?? false,
      price: currentEvent.isPaidEvent ? currentEvent.price ?? 0 : 0,
      eventTemplateList: [],
      eventTemplateId: 0,
    });

    const schema = Yup.object().shape({
      name: Yup.string().required("Event name is required"),
      eventDate: Yup.string().required("Event date is required"),
      password: Yup.lazy(() => (settings.isProtect ? Yup.string().required("password is required") : Yup.string())),
      price: Yup.lazy(() => (data.isPaidEvent ? Yup.string().required("Price is required") : Yup.string())),
    });
    const eventUrl = process.env.VUE_APP_URL;

    onBeforeMount(getTemplates);

    async function getTemplates() {
      try {
        const resp = await getEventTemplates();
        data.eventTemplateList = resp.data;
      } catch (error) {
        console.log({ error });
      }
    }
    watch(
      () => data.eventTemplateId,
      value => {
        emit(
          "onChangeTemplate",
          _.find(data.eventTemplateList, item => item.id === value.value),
        );
      },
    );

    watch(
      () => props.eventDetail,
      value => {
        const { primaryColour, secondaryColor, font, preEventConfig, postEventConfig, customCSS, settings: settingTemmplate, loginMode, price, isPaidEvent } = value;
        currentEvent.primaryColour = primaryColour;
        currentEvent.secondaryColor = secondaryColor;
        currentEvent.preEventConfig = preEventConfig;
        currentEvent.postEventConfig = postEventConfig;
        currentEvent.customCSS = customCSS;
        data.currentFont = font;
        data.isPaidEvent = isPaidEvent;
        data.price = price;
        data.loginMode = loginMode;
        settings.isProtect = settingTemmplate.isProtect;
        settings.password = settingTemmplate.password;
      },
    );

    return {
      currentEvent,
      data,
      schema,
      settings,
      eventUrl,
    };
  },
  methods: {
    async onSubmit() {
      try {
        this.$store.dispatch("updateLoadingText", "Updating event");
        this.$emit("toggleLoading");
        const payload = {
          ...this.currentEvent,
          dateTime: getMomentT(this.data.date).format(),
          settings: {
            ...this.currentEvent.settings,
            ...this.settings,
          },
          loginMode: this.data.loginMode,
          price: this.data.isPaidEvent ? this.data.price : 0,
          isPaidEvent: this.data.isPaidEvent,
        };
        _.forOwn(payload, (value, key) => {
          if (key.includes("Url") && (!value.url.length || value.url === " ")) delete payload[key];
        });
        const result = await putEvent(payload);
        if (result.success) {
          this.$emit("onUpdate");
        }
        // Show Alert
        this.$store.dispatch("toggleAlert", {
          isAlert: true,
          status: result.success,
          title: result.success ? "Success!" : "Failed!",
          message: result.message,
        });
      } catch (error) {
        console.log({ error });
      } finally {
        this.$emit("toggleLoading", false);
        await sleep(5000);
        // Hide Alert
        this.$store.dispatch("toggleAlert", {
          isAlert: false,
        });
      }
    },
  },
};
</script>
