import Repository from "@API/repository";

export const getCommentApproved = async eventId => {
  const url = `event/${eventId}/comment/approved`;
  const response = await Repository({
    url,
    method: "GET",
  });
  return response.data;
};

export const getCommentWaiting = async eventId => {
  const url = `event/${eventId}/comment/waiting`;
  const response = await Repository({
    url,
    method: "GET",
  });
  return response.data;
};

export const doUpdateCommentStatus = async (eventId, data) => {
  const url = `event/${eventId}/comment`;
  const response = await Repository({
    url,
    method: "PUT",
    data,
  });
  return response.data;
};

export const doDeleteCommentStatus = async eventId => {
  const url = `event/${eventId}/comment`;
  const response = await Repository({
    url,
    method: "DELETE",
  });
  return response.data;
};
