<template>
  <Form class="flex flex-col font-fs-elliot-pro py-3 md:py-7 lg:py-5 mb-8 md:mb-0" @submit="onSubmit">
    <div class="flex flex-col md:flex-row md:mb-7 lg:mb-5">
      <div class="w-full md:w-1/3 lg:w-1/4 mt-2 md:mt-0 md:pt-0">
        <label class="block capitalize tracking-wide text-grey-mid text-xs md:text-lg font-montserrat font-semibold pt-2.5"> Primary Logo: </label>
      </div>
      <div class="md:flex md:items-center w-2/3 mt-2 md:mt-0 md:w-auto lg:w-2/3">
        <label
          class="
            w-60
            lg:w-60
            h-20
            lg:h-24
            flex flex-col
            relative
            items-center
            bg-off-white-1
            dark:bg-dark-bgPrimary
            text-black-1
            dark:text-dark-textPrimary
            rounded-lg
            border border-grey-mid
            focus:ring-magenta focus:border-magenta
            cursor-pointer
            md:ml-0
          "
        >
          <template v-if="empty(eventImage.charityLogo)">
            <XLogo class="h-8 w-8 text-blue-light absolute inset-y-1/4 left-5 lg:left-1/8" />
            <span class="font-bold absolute top-1/5 left-14 lg:left-1/4">
              <p>Logo</p>
              suggested: 300x60
            </span>
          </template>
          <img v-else :src="eventImage.charityLogo" alt="Client Logo" class="h-full" />
          <input name="charityLogo" type="file" class="hidden" v-on:change="handleChangeFilesUpload" accept="image/png, image/jpeg" />
        </label>

        <div class="flex" v-if="!empty(eventImage.charityLogo)">
          <button
            type="button"
            class="bg-transparent text-lg lg:text-xl text-magenta font-bold inline-flex items-center border-none overflow-hidden focus:outline-none p-2 lg:px-4"
            @click="handleDeleteFilesUpload('charityLogo')"
          >
            <DeleteIcon class="w-6 h-6 mr-1 lg:mr-2" />
            <span>Delete</span>
          </button>
        </div>
      </div>
    </div>

    <div class="flex flex-col md:flex-row md:mb-7 lg:mb-5">
      <div class="w-full md:w-1/3 lg:w-1/4 mt-2 md:mt-0 md:pt-0">
        <label class="block capitalize tracking-wide text-grey-mid text-xs md:text-lg font-montserrat font-semibold pt-2.5"> Additional Logo(s): </label>
      </div>
      <div class="md:flex md:items-center w-2/3 mt-2 md:mt-0 md:w-auto lg:w-2/3">
        <label
          class="
            w-60
            lg:w-60
            h-20
            lg:h-24
            flex flex-col
            relative
            items-center
            bg-off-white-1
            dark:bg-dark-bgPrimary
            text-black-1
            dark:text-dark-textPrimary
            rounded-lg
            border border-grey-mid
            focus:ring-magenta focus:border-magenta
            cursor-pointer
            md:ml-0
          "
          v-if="empty(eventImage.sponsorLogo)"
        >
          <XLogo class="h-8 w-8 text-magenta absolute inset-y-1/4 left-5 lg:left-1/8" />
          <span class="font-bold absolute top-1/5 left-14 lg:left-1/4">
            <p>Logo</p>
            Suggested: 300x60
          </span>
          <input name="sponsorLogo" type="file" class="hidden" @change="handleChangeFilesUpload" accept="image/png, image/jpeg" />
        </label>
        <div class="flex flex-wrap" v-else>
          <label v-for="(image, index) in eventImage.sponsorLogo" :key="index" class="relative mt-6 self-start cursor-pointer mr-10">
            <div class="image-wrapper">
              <img :src="image" alt="Additional Logo" class="h-20 lg:h-24" />
            </div>
            <DeleteIcon class="w-6 h-6 absolute top-0 -right-6" @click.prevent="handleDeleteFilesUpload('sponsorLogo', index, image)" />
            <input name="sponsorLogo" type="file" class="hidden" @change="e => handleChangeFilesUpload(e, index)" accept="image/png, image/jpeg" />
          </label>
        </div>

        <label v-if="!empty(eventImage.sponsorLogo)" class="w-40 lg:w-60 h-20 lg:h-24 flex flex-col relative items-start cursor-pointer">
          <div class="w-40 h-full absolute flex items-center text-lg lg:text-xl text-magenta font-bold">
            <AddIcon class="h-6 w-6 mr-1 lg:mr-2" />
            <span class="font-bold"> Add Another </span>
          </div>
          <input name="sponsorLogo" type="file" class="hidden" v-on:change="handleChangeFilesUpload" accept="image/png, image/jpeg" />
        </label>
      </div>
    </div>

    <div class="flex flex-col md:flex-row md:mb-7 lg:mb-5">
      <div class="w-full md:w-1/3 lg:w-1/4 mt-2 md:mt-0 md:pt-0">
        <label class="block capitalize tracking-wide text-grey-mid text-xs md:text-lg font-montserrat font-semibold pt-2.5"> Registration page background image: </label>
      </div>
      <div class="md:flex md:items-center w-2/3 mt-2 md:mt-0 md:w-auto lg:w-2/3">
        <label
          :class="{ 'py-7': empty(eventImage.backgroundImage) }"
          class="
            w-60
            lg:w-60
            h-20
            lg:h-24
            flex flex-col
            relative
            items-center
            bg-off-white-1
            dark:bg-dark-bgPrimary
            text-black-1
            dark:text-dark-textPrimary
            rounded-lg
            border border-grey-mid
            focus:ring-magenta focus:border-magenta
            cursor-pointer
            md:ml-0
          "
        >
          <QRCodeLogo v-if="empty(eventImage.backgroundImage)" class="h-8 w-8" />
          <img v-else :src="eventImage.backgroundImage" alt="Background Image" class="h-full" />
          <input name="backgroundImage" type="file" class="hidden" v-on:change="handleChangeFilesUpload" accept="image/png, image/jpeg" />
        </label>
        <template v-if="!empty(eventImage.backgroundImage)">
          <button
            type="button"
            class="bg-transparent text-lg lg:text-xl text-magenta font-bold inline-flex items-center border-none overflow-hidden focus:outline-none p-2 lg:px-4"
            @click="handleDeleteFilesUpload('backgroundImage')"
          >
            <DeleteIcon class="w-6 h-6 mr-1 lg:mr-2" />
            <span>Delete</span>
          </button>
        </template>
      </div>
    </div>

    <div class="flex flex-col md:flex-row md:mb-7 lg:mb-5">
      <div class="mt-2 md:mt-0 w-full md:w-1/3 lg:w-1/4">
        <label class="block capitalize tracking-wide text-grey-mid text-xs md:text-lg font-montserrat font-semibold pt-2.5"> Primary color: </label>
      </div>
      <div class="md:flex md:items-center w-2/3 mt-2 md:mt-0 md:w-auto lg:w-2/3">
        <div class="relative flex items-center">
          <span class="absolute left-4 text-lg font-semibold text-grey-mid"> # &nbsp; {{ currentEvent.primaryColour.substring(1) }} </span>

          <span class="absolute left-11.5 w-1px h-12 bg-grey-mid"></span>

          <Field
            v-model="currentEvent.primaryColour"
            name="primaryColor"
            type="color"
            class="
              h-12
              w-52
              md:w-60
              text-lg text-left
              font-semibold
              appearance-none
              bg-off-white-1
              dark:bg-dark-bgPrimary
              text-grey-dark
              dark:text-dark-textPrimary
              border border-grey-mid
              focus:border-magenta focus:outline-none focus:ring-1 focus:ring-magenta
              rounded
              p-0.5
              pl-11.75
              pr-1
            "
          />
        </div>
      </div>
    </div>

    <div class="flex flex-col md:flex-row md:mb-7 lg:mb-5">
      <div class="mt-2 md:mt-0 w-full md:w-1/3 lg:w-1/4">
        <label class="block capitalize tracking-wide text-grey-mid text-xs md:text-lg font-montserrat font-semibold pt-2.5"> Secondary color: </label>
      </div>
      <div class="md:flex md:items-center w-2/3 mt-2 md:mt-0 md:w-auto lg:w-2/3">
        <div class="relative flex items-center">
          <span class="absolute left-4 text-lg font-semibold text-grey-mid"># &nbsp; {{ currentEvent.secondaryColor.substring(1) }}</span>

          <span class="absolute left-11.5 w-1px h-12 bg-grey-mid"></span>

          <Field
            v-model="currentEvent.secondaryColor"
            name="secondaryColor"
            type="color"
            class="
              h-12
              w-52
              md:w-60
              text-lg text-left
              font-semibold
              appearance-none
              bg-off-white-1
              dark:bg-dark-bgPrimary
              text-grey-dark
              dark:text-dark-textPrimary
              border border-grey-mid
              focus:border-magenta focus:outline-none focus:ring-1 focus:ring-magenta
              rounded
              p-0.5
              pl-11.75
              pr-1
            "
          />
        </div>
      </div>
    </div>

    <div class="flex flex-col md:flex-row md:mb-7 lg:mb-5">
      <div class="mt-2 md:mt-0 w-full md:w-1/3 lg:w-1/4">
        <label class="block capitalize tracking-wide text-grey-mid text-xs md:text-lg font-montserrat font-semibold pt-2.5"> Font: </label>
      </div>
      <div class="md:flex md:items-center w-2/3 mt-2 md:mt-0 lg:w-2/3">
        <ComboBox
          class="w-full"
          v-model="data.currentFont"
          :options="[
            { label: 'Mulish', value: `'Mulish', sans-serif` },
            { label: 'Inconsolata', value: `'Inconsolata', monospace` },
            { label: 'Montserrat', value: `'Montserrat', sans-serif` },
            { label: 'Noto Sans Mono', value: `'Noto Sans Mono', monospace` },
            { label: 'Poppins', value: `'Poppins', sans-serif` },
            { label: 'Roboto', value: `'Roboto', sans-serif` },
            { label: 'Local font', value: '' },
            {
              label: 'Open Sans',
              value: `'Open Sans', sans-serif`,
            },
          ]"
        />
      </div>
    </div>

    <div class="flex flex-col md:flex-row md:mb-7 lg:mb-8">
      <div class="w-1/2 md:w-1/3 lg:w-1/4">
        <label class="block capitalize tracking-wide text-grey-mid text-xs md:text-base font-montserrat font-semibold pt-2.5"> Custom css: </label>
      </div>
      <div class="w-full md:w-2/3 mt-2 md:mt-0 flex flex-col">
        <Field
          v-model="currentEvent.customCSS"
          as="textarea"
          name="customCSS"
          rows="5"
          class="
            w-full
            text-xs
            md:text-base
            text-black-1
            dark:text-dark-textPrimary
            bg-off-white-1
            dark:bg-dark-bgPrimary
            border border-grey-mid
            focus:ring-magenta focus:border-magenta
            rounded
            p-2
            md:p-3
          "
        />
        <a href="javascript:void(0)" class="mt-2 text-magenta underline" @click="data.showCssHelp = true">Css help</a>
      </div>
      <Popup :isShow="data.showCssHelp" @onClose="data.showCssHelp = false" />
    </div>

    <div class="flex mb-7 lg:mb-8">
      <div class="md:w-1/3 lg:w-1/4">
        <label class="block capitalize tracking-wide text-grey-mid text-xs md:text-base font-montserrat font-semibold pt-2.5"> Ticker Bar Message: </label>
      </div>
      <div class="ml-4 md:ml-0 md:w-2/3 mt-2 md:mt-0">
        <Switch v-model="data.isShowTicker" class="mb-2 self-start" />
      </div>
    </div>
    <template v-if="data.isShowTicker">
      <div class="flex items-center mb-7 lg:mb-8">
        <div class="md:w-1/3 lg:w-1/4">
          <label class="block capitalize tracking-wide text-grey-mid text-xs md:text-base font-montserrat font-semibold pt-2.5"> Ticket Bar: </label>
        </div>
        <div class="w-full md:w-2/3 mt-2 md:mt-0">
          <ComboBox
            class="w-full"
            v-model="data.eventMode"
            :options="[
              { label: 'Message', value: 'MESSAGE' },
              { label: 'Isi API Data', value: 'DONATE' },
            ]"
          />
        </div>
      </div>
      <div v-if="data.eventMode === 'MESSAGE'" class="flex items-center mb-7 lg:mb-8">
        <div class="md:w-1/3 lg:w-1/4">
          <label class="block capitalize tracking-wide text-grey-mid text-xs md:text-base font-montserrat font-semibold pt-2.5"> Message: </label>
        </div>
        <div class="w-full md:w-2/3 mt-2 md:mt-0">
          <Field
            name="eventModeMessage"
            v-model="currentEvent.eventModeMessage"
            as="textarea"
            class="
              w-full
              text-xs
              md:text-base
              text-black-1
              dark:text-dark-textPrimary
              bg-off-white-1
              dark:bg-dark-bgPrimary
              border border-grey-mid
              focus:ring-magenta focus:border-magenta
              rounded
              p-2
              md:p-3
            "
          />
        </div>
      </div>
    </template>

    <button
      type="submit"
      class="button_large-Alternative absolute right-0 bottom-0 md:bottom-auto md:-top-8 md:-right-8 uppercase bg-magenta border rounded-full py-3 lg:py-4 lg:px-8 focus:outline-none"
    >
      Save
    </button>
  </Form>
</template>

<script>
import { watch, reactive, computed } from "vue";
import { Form, Field } from "vee-validate";
import { TweenMax } from "gsap";
import { forOwn, isString, cloneDeep, keys, startCase, camelCase, get, filter } from "lodash";
import axios from "axios";

import { sleep } from "@Helpers/sleep";
import { putEvent, getS3UploadKey } from "@API/event/event-repository";

import XLogo from "@Assets/images/XLogo";
import DeleteIcon from "@Assets/images/DeleteIcon";
import AddIcon from "@Assets/images/AddIcon";
import QRCodeLogo from "@Assets/images/QRCodeLogo";
import ComboBox from "@/components/layouts/ComboBox";
import Popup from "./Popup";
import Switch from "@/components/layouts/Switch.vue";

import { empty, isFile } from "@/utils";

export default {
  name: "DesignTab",
  props: {
    eventDetail: {
      type: Object,
      required: true,
    },
  },
  components: {
    Form,
    Field,
    XLogo,
    DeleteIcon,
    AddIcon,
    QRCodeLogo,
    Switch,
    ComboBox,
    Popup,
  },
  emits: ["toggleLoading", "onUpdate"],
  setup(props) {
    const data = reactive({
      currentFont: props.eventDetail.font ?? "",
      eventMode: props.eventDetail.eventMode ?? "MESSAGE",
      isShowTicker: get(props.eventDetail, "settings.bottomBar") === "SHOW",
      showCssHelp: false,
    });

    const currentEvent = computed(() => {
      const event = props.eventDetail;
      return {
        ...event,
        sponsorLogo: empty(event.sponsorLogo) ? [] : isString(event.sponsorLogo) ? [event.sponsorLogo] : event.sponsorLogo,
      };
    });

    watch(
      () => props.eventDetail,
      value => {
        data.currentFont = value.font;
        data.eventMode = value.eventMode;
        data.isShowTicker = get(value, "settings.bottomBar") === "SHOW";
      },
    );

    return {
      data,
      currentEvent,
    };
  },
  data() {
    return {
      filesUpload: {
        charityLogo: null,
        sponsorLogo: [],
        backgroundImage: null,
      },
      eventImage: {},
      payload: {},
    };
  },
  watch: {
    currentEvent: {
      handler(event) {
        this.eventImage = {
          charityLogo: event.charityLogo,
          backgroundImage: event.backgroundImage,
          sponsorLogo: [...event.sponsorLogo],
        };
        this.payload = {
          charityLogo: event.charityLogo,
          backgroundImage: event.backgroundImage,
          sponsorLogo: [...event.sponsorLogo],
        };
        this.filesUpload = {
          charityLogo: event.charityLogo,
          backgroundImage: event.backgroundImage,
          sponsorLogo: [...event.sponsorLogo],
        };
      },
      immediate: true,
    },
  },
  methods: {
    empty,
    handleDeleteFilesUpload(property, index, image) {
      let logos = "";
      if (property === "sponsorLogo") {
        logos = this.eventImage.sponsorLogo;
        logos.splice(index, 1);
        this.payload.sponsorLogo.splice(index, 1);
        this.filesUpload.sponsorLogo.splice(index, 1);
      } else {
        this.filesUpload = {
          ...this.filesUpload,
          [property]: null,
        };
        this.payload = {
          ...this.payload,
          [property]: logos,
        };
      }
      this.eventImage = {
        ...this.eventImage,
        [property]: logos,
      };
    },
    handleChangeFilesUpload(e, index) {
      const fileSelected = e.target.files[0];
      let reader = new FileReader();
      reader.onload = event => {
        if (e.target.name !== "sponsorLogo") {
          this.filesUpload[e.target.name] = fileSelected;
          this.eventImage = {
            ...this.eventImage,
            [e.target.name]: event.target.result,
          };
        } else {
          const logos = this.eventImage.sponsorLogo || [];
          if (index > -1) {
            logos[index] = event.target.result;
            this.filesUpload[e.target.name][index] = fileSelected;
            this.payload.sponsorLogo.splice(index, 1);
          } else {
            this.filesUpload[e.target.name].push(fileSelected);
            logos.push(event.target.result);
          }
          this.eventImage = {
            ...this.eventImage,
            [e.target.name]: logos,
          };
        }
      };
      reader.readAsDataURL(fileSelected);
    },
    async doUpdateFile(file, property) {
      if (!file?.type?.includes("image/")) return;

      const s3Result = await getS3UploadKey(file.type.replace("image/", ""));

      const {
        data: { fields, url },
      } = s3Result;

      const formData = new FormData();

      Object.keys(fields).forEach(key => {
        formData.append(key, fields[key]);
      });
      // Actual file has to be appended last.
      formData.append("file", file);

      const result = await axios.post(url, formData, {});

      if (result.status === 204) {
        if (property === "sponsorLogo") {
          this.payload[property] = this.payload[property] || [];
          this.payload[property].push(fields.key);
        } else {
          this.payload[property] = fields.key;
        }
      }

      // Show Alert\
      this.$store.dispatch("toggleAlert", {
        isAlert: true,
        status: result.status === 204,
        title: `[${result.status === 204 ? "Successfully" : "Failed"}] ${startCase(camelCase(property))}`,
        message: result.status === 204 ? "Upload file successfully!" : "Upload file failed!",
      });

      await sleep(1600);

      // Hide Alert
      this.$store.dispatch("toggleAlert", {
        isAlert: false,
      });
    },
    async handleUploadFiles(property) {
      // this.$emit("toggleLoading");
      this.$store.dispatch("updateLoadingText", "Updating " + startCase(camelCase(property)));
      if (property === "sponsorLogo") {
        const fileUploads = filter(this.filesUpload[property], file => isFile(file));
        for (let i = 0; i < fileUploads.length; i++) {
          await this.doUpdateFile(fileUploads[i], property);
        }
      } else {
        await this.doUpdateFile(this.filesUpload[property], property);
      }
      // this.$emit("toggleLoading");
    },
    async onSubmit() {
      try {
        this.$store.dispatch("updateLoadingText", "Preparing");
        this.$emit("toggleLoading");
        const payload = {
          ...this.currentEvent,
          font: this.data.currentFont,
          // eventMode: this.data.eventMode,
          eventMode: "MESSAGE",
          settings: {
            ...get(this.currentEvent, "settings", {}),
            bottomBar: this.data.isShowTicker ? "SHOW" : "HIDE",
          },
        };

        forOwn(payload, (value, key) => {
          if (key.includes("Url") && (!value.url.length || value.url === " ")) delete payload[key];
        });
        const filesUploadKeys = keys(this.filesUpload);

        for (let i = 0; i < filesUploadKeys.length; i++) {
          const file = this.filesUpload[filesUploadKeys[i]];
          if (isFile(file) || !empty(file)) {
            await this.handleUploadFiles(filesUploadKeys[i]);
          }
        }
        this.$store.dispatch("updateLoadingText", "Updating event");
        const result = await putEvent({
          ...payload,
          ...this.payload,
        });

        if (result.success) {
          this.$emit("onUpdate");
        }
        // Show Alert
        this.$store.dispatch("toggleAlert", {
          isAlert: true,
          status: result.success,
          title: result.success ? "Success!" : "Failed!",
          message: result.message,
        });
      } catch (error) {
        console.log({ error });
      } finally {
        this.$emit("toggleLoading", false);

        await sleep(5000);
        // Hide Alert
        this.$store.dispatch("toggleAlert", {
          isAlert: false,
        });
      }
    },
  },
};
</script>
<style lang="postcss" scoped>
.image-wrapper:hover::after {
  content: "✚";
  @apply absolute text-white text-5xl;
  top: 30%;
  left: 50%;
  transform: translateX(-50%);
}
.image-wrapper:hover::before {
  content: "";
  @apply bg-magenta opacity-25 absolute h-full w-full;
}
</style>
